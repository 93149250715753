import { StringUtils } from '../utl/StringUtils';

/**
 * Kwota wyrażona w 1/100 jednostki
 */
export type Kwota0 = number;

export type Kwota = number;
/**
 * Zaklada ze uzytkownik wpisal wartosc z 2 miejcami po przecinku i odcina dalsze
 * @param v
 */
export function userInputToKwota0(v: Kwota): Kwota0
{
  return Math.trunc(v * 100.0);
}

export function userInputStrToKwota0(v: string): Kwota0
{
  const s = v.replace(',', '.').replace(/[^0-9.]/g, '');
  const n = +s;
  return Math.trunc(n * 100.0);
}

/**
 * Z wyniku obliczenia - zaokragla automatycznie
 * @param v
 */
export function floatToKwota0(v: Kwota): Kwota0
{
  return Math.round(v * 100.0);
}

/**
 * Do wartości do prezentacji
 * @param v
 */
export function kwota0ToFloat(v: Kwota0): Kwota
{
  return v / 100.0;
}

/**
 * Zaokrąglenie do pelnej
 */
export function kwota0Round(v: Kwota0): Kwota0
{
  return Math.round(v / 100.0) * 100.0;
}

// pasuje do cyfry pod warunkiem że po niej następuje jedna lub więcej grupy trzycyfrowe, a po nich koniec
const fmtRe = /(\d)(?=(\d{3})+$)/g;
const nbsp = '\xa0';

/**
 * Postać prezentacyjna kwoty
 * sep tysięcy    - nbsp
 * sep dziesiętny - kropka
 */
export function kwota0ToStrTys(v: Kwota0): string
{
  const av = Math.abs(v);
  const w = Math.floor(av / 100.0).toString().replace(fmtRe, '$1' + nbsp);
  const c = Math.floor(av % 100);
  const f = ((c < 10) ? '0' : '') + String(c);
  return (v < 0 ? '-' : '') + w + (c > 0 ? '.' + f : '');
}

export function kwota0slownieZl(v: Kwota0): string
{
  const zl = Math.trunc(v / 100);
  // dodajemy 100 odcinamy 1 znak dostajemy z zerem wiodacym
  const gr = String(100 + (Math.abs(v) % 100)).substring(1);
  return StringUtils.slownie(zl) + ' ' +
    StringUtils.slownieWiele(zl, 'złoty', 'złote', 'złotych') + ' ' +
    gr + '/100';
}
