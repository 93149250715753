import { DocField } from '../FormDoc';

export class DocNumberField extends DocField<number>
{
}

export class DocIntegerField extends DocField<number>
{
  public setValue(val: number | undefined): void
  {
    if (val !== undefined)
    {
      super.setValue(Math.trunc(val));
    }
    else
    {
      super.setValue(val);
    }
  }
}

export class DocKwota0Field extends DocField<number>
{
}

export class DocProcent0Field extends DocField<number>
{
  /**
   * Wartość przeliczona z procenta na ułamek (100% => 1)
   */
  public getDefinedFractionalValue()
  {
    return this.getDefinedValue() / 10000;
  }

  /**
   * Wartość przeliczona z procenta na ułamek (100% => 1)
   */
  public getFractionalValue()
  {
    const v = this.getValue();
    return (v !== undefined) ? v / 10000 : undefined;
  }
}

export class DocProcent2Field extends DocField<number>
{
  public isControlEmpty(): boolean
  {
    return !DocProcent2Field.isNumber(this.getControlValue());
  }

  public static isNumber(x: any): x is number
  {
    return typeof x === 'number';
  }

}
