import { ListElement, FormDoc, FormDocData } from './FormDoc';
import { DocTextField } from './fields/DocTextField';
import { DocNumberField } from './fields/DocNumberField';
import { IStateSource } from '../state/State';

export class StorageMeta extends ListElement
{
  public readonly className = new DocTextField('className', 'Nazwa klasy', this);
  public readonly classVersion = new DocNumberField('classVersion', 'Wersja klasy', this);
}

export class StorableDoc extends FormDoc
{
  public readonly id = new DocTextField('id', 'id', this);
  public readonly version = new DocNumberField('version', 'Wersja sprawy', this);
  // server side only
  public readonly storageMeta = this.SubObject(StorageMeta, 'storageMeta');

  public constructor(
    stateSource: IStateSource)
  {
    super(new FormDocData(stateSource, 'data'));
  }

  get isNew(): boolean
  {
    return this.version.getValue() === undefined;
  }

}

export type StorableDocConstructor<T extends StorableDoc> = new (stateSource: IStateSource) => T;
