
import { isNormalizedPLPhoneNumber } from '../../utl/PhoneNumberUtils';
import { FieldValidator, DocField } from '../FormDoc';

class PLPhoneNumberValidatorImpl implements FieldValidator<any> {

  public validate(field: DocField<any>): string[]
  {
    const fieldValue = field.getControlValue();
    if ((fieldValue !== undefined) && !isNormalizedPLPhoneNumber(fieldValue))
    {
      return [
        'Nieprawidłowa postać numeru telefonu. Oczekiwana "### ### ###" lub "+48 ### ### ###"'
      ];
    }
    return [];
  }
}

export const PLPhoneNumberValidator = new PLPhoneNumberValidatorImpl();
