import { DocField } from '../FormDoc';
import { LocalDateTime } from '../../type/LocalDateTime';

export class DocLocalDateTimeField extends DocField<LocalDateTime> {

  public eqSaved(): boolean
  {
    const saved = this.savedValue;
    const current = this.getValue();

    if (current)
    {
      return current.eq(saved);
    }

    return saved === undefined || saved === null;
  }

  protected valueToJson(data: LocalDateTime | undefined): any
  {
    return data ? data.toJSON() : undefined;
  }

  protected jsonToValue(json: any): LocalDateTime | undefined
  {
    return LocalDateTime.fromJSON(json);
  }

}
