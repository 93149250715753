import { FieldValidator, DocField } from '../FormDoc';

/**
 * Walidator na wartość logiczną (flagę), której wartość powinna być zawsze określona i równa
 * informacji czy inne pole jest defined
 */
export class EqualsAnotherValueDefined implements FieldValidator<boolean> {

  /**
   * Walidator na wartość logiczną (flagę), której wartość powinna być zawsze określona i równa
   * informacji czy inne pole jest defined
   * @param anotherField pole którego stan undefined powinien być odzwierciewdlony w sprawdzanej fladze
   */
  constructor(public readonly anotherField: DocField<any>) { }

  public validate(field: DocField<boolean>): string[]
  {
    // Jeśli jest podana wartość, to ma być tożsama wynikowi sprawdzenia anotherField !== undefined
    const fv = field.getValue();
    if ((fv === true) !== (this.anotherField.getValue() !== undefined))
    {
      return ['Wartość pola logicznego "' + field.fieldLabel +
        '" powinna być spójna ze stanem wypełnienia wartości "' + this.anotherField.fieldLabel + '"'];
    }
    return [];
  }

}

export class ValueRequired implements FieldValidator<boolean>
{
  constructor(public readonly requireMessage: string, private expectedValue: boolean = true) { }

  public validate(field: DocField<boolean>): string[]
  {

    const fv = field.getControlValue();
    if (fv !== undefined)
    {
      if (fv !== this.expectedValue)
      {
        return [this.requireMessage];
      }
    }

    return [];
  }
}
