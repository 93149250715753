/** Kierunkowe stacjonarne */
const regionalPre = [
  '12', '13', '14', '15', '16', '17', '18',
  '22', '23', '24', '25', '29', '32', '33', '34',
  '41', '42', '43', '441', '442', '443', '445', '446', '447', '448', '449', '440', '46', '48',
  '52', '54', '55', '56', '58', '59',
  '61', '62', '63', '65', '67', '68',
  '71', '74', '75', '76', '77',
  '81', '82', '83', '84', '85', '86', '87', '89',
  '91', '94', '95'
];

/** Kierunkowe mobilne */
const mobilePre = [
  '45', '50', '51', '53', '57', '60', '66', '69', '72', '73', '78', '79', '88'
];

// separatory wycinane z numeru (białe, -, nawiasy)
const reSep = /[-\s()]/g;
// czy wpisano 9 cyfr
const reRead9 = /^[0-9]{9}$/;
// czy wpisano kierunkowy do kraju i 9 cyfr
const reRead489 = /^((00)|(\+))48([0-9]{9})$/;

/**
 * Parsuje tekst, oddaje znormalizowaną postać dla numeru komórkowego z Polski
 * Odda bez zmian jeśli nie pasuje do wzorca
 * @param s
 */
export function normalizePLPhoneNumber(tel: string | undefined): string | undefined
{
  if ((tel !== undefined) && (tel.length >= 9))
  {
    // 123123123
    // 123 123 123
    // 123-123-123
    // +48 ....
    // 0048 ...
    // 022333444455
    const noSep = tel.replace(reSep, '');
    if ((noSep !== '') && (noSep.length >= 9))
    {
      // 9 cyfr
      if (reRead9.test(noSep))
      {
        return '+48' + noSep;
      }
      // kier do PL + 9 cyfr
      else if (reRead489.test(noSep))
      {
        const m = reRead489.exec(noSep);
        if (m != null)
        {
          return '+48' + m[4];
        }
      }
      // '0' + 9 cyfr
      else if ((noSep[0] === '0') && reRead9.test(noSep.substring(1)))
      {
        return '+48' + noSep.substring(1);
      }
    }
    return tel;
  }
  return undefined;
}

const reNorm = /^(\+48)([0-9]{9})$/;

/**
 * Oddaje czy argument jest PL numerem telefonu znormalizowanym
 * @param tel
 */
export function isNormalizedPLPhoneNumber(tel: string): boolean
{
  return reNorm.test(tel);
}

export function isNormalizedPLMobilePhoneNumber(tel: string): boolean
{
  return reNorm.test(tel) && mobilePre.some((pre) => tel.substring(3, 10).startsWith(pre));
}

const reShow = /^(\+48)([0-9]{9})$/;

/**
 * Oddaje postać prezentacyjną dla znormalizowanego numeru telefonu
 * @param tel - znormalizowany
 * @param int - czy pokazać prefix miedzynarodowy, domyślnie false
 */
export function prettyPrintPLPhoneNumber(tel: string, int?: boolean): string
{
  if (tel !== '')
  {
    // 1: +48
    // 2:    012345678
    const match = reShow.exec(tel);
    if (match !== null && match[0] === tel)
    {
      const m2 = match[2];
      if (regionalPre.some((pre) => m2.startsWith(pre)))
      {
        // +48 01 234 56 78
        return ((int === true) ? match[1] + ' ' : '') +
          m2.substring(0, 2) + ' ' +
          m2.substring(2, 5) + ' ' +
          m2.substring(5, 7) + ' ' +
          m2.substring(7, 9);
      }
      else
      {
        // +48 012 345 678
        return ((int === true) ? match[1] + ' ' : '') +
          m2.substring(0, 3) + ' ' +
          m2.substring(3, 6) + ' ' +
          m2.substring(6, 9);
      }
    }
  }
  return tel;
}
