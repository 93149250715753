exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-tsx": () => import("./../../../src/pages/a.tsx" /* webpackChunkName: "component---src-pages-a-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-nnw-dziecka-kup-ubezpieczenie-tsx": () => import("./../../../src/pages/nnw-dziecka/kup-ubezpieczenie.tsx" /* webpackChunkName: "component---src-pages-nnw-dziecka-kup-ubezpieczenie-tsx" */),
  "component---src-pages-nnw-dziecka-status-platnosci-tsx": () => import("./../../../src/pages/nnw-dziecka/status-platnosci.tsx" /* webpackChunkName: "component---src-pages-nnw-dziecka-status-platnosci-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */)
}

