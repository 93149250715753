/**
 * Błąd, który jest łapany w generycznym ruterze i tłumaczony
 * na odpowiedź http o kodzie @httpCode i treści @message
 */
export class RpcError extends Error
{
  public constructor(
    public readonly httpCode: number,
    public readonly json: any,
  )
  {
    super();
  }
}
