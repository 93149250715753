export type Resolver<T> = (value?: T | PromiseLike<T>) => void;
export type Rejecter = (reason?: any) => void;

export class ManualPromise<T>
{
  private presolve?: Resolver<T>;
  private preject?: Rejecter;
  public readonly promise: Promise<T>;

  public constructor(private value?: T)
  {
    this.promise = new Promise(
      (resolve: Resolver<any>, reject: Rejecter) =>
      {
        this.presolve = resolve;
        this.preject = reject;
      }
    );
  }

  public resolve(value?: T): void
  {
    if (this.presolve)
    {
      this.presolve(value === undefined ? this.value : value);
    }
    else
    {
      throw new Error('There is no resolve handler assigned');
    }
  }

  public reject(reason?: any): void
  {
    if (this.preject)
    {
      this.preject(reason);
    }
    else
    {
      throw new Error('There is no reject handler assigned');
    }
  }
}
