import * as EmailValidatorUtl from 'email-validator';
import { FieldValidator, DocField } from '../FormDoc';

export class EmailValidator implements FieldValidator<string> {

  public validate(field: DocField<string>): string[]
  {
    if (field.isControlEmpty())
    {
      return [];
    }
    return EmailValidatorUtl.validate(field.getControlValue() || '')
      ? []
      : ['Błędny adres email'];
  }
}

export class EmailListValidator implements FieldValidator<string> {

  public validate(field: DocField<string>): string[]
  {
    if (!field.isControlEmpty())
    {
      const list = (field.getControlValue() || '').split(',');
      for (const email of list)
      {
        if (!EmailValidatorUtl.validate(email))
        {
          return ['Błędny adres email: ' + email];
        }
      }
    }
    return [];
  }

}
