import React from 'react';
import { useMounted, MountedState } from './MountedHook';
import { BaseStateSource } from '../state/ObjectStateSource';
import { IStateSource } from '../state/State';

interface State
{
  liveMap: any;
  stateMap: any;
}

export function useStateSource()
{
  const [state, setState] = React.useState<State>({ liveMap: {}, stateMap: {} });
  const mountedState = useMounted();

  return new HookedStateSource(state, setState, mountedState);
}

class HookedStateSource extends BaseStateSource
{
  public constructor(
    private state: State,
    private setState: (state: State) => void,
    private mountedState: MountedState,
  )
  {
    super(state.liveMap);
  }

  protected stateMap(): any
  {
    return this.state.stateMap;
  }

  protected updateState(): Promise<void>
  {
    return new Promise((resolve) =>
    {
      if (this.mountedState.isMounted)
      {
        this.setState({ stateMap: { ...this.state.liveMap }, liveMap: this.state.liveMap });
        resolve();
      }
      else
      {
        resolve();
      }
    });
  }

  public eqCaptured(o: IStateSource): boolean
  {
    if (o instanceof HookedStateSource)
    {
      return this.stateMap() === o.stateMap();
    }

    return false;

  }

}
