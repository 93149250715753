
import { WithShouldMarkFieldAsRequired, IsWithShouldMarkFieldAsRequired } from './FieldNotEmpty';
import { FieldValidator, DocField } from '../FormDoc';

/**
 * Odpala walidator another tylko jesli pole jest widoczne i nie readonly
 * @param another Walidator który zostanie uruchomiony
 */
export function ConditionalValidator<T>(cond: () => boolean, another: FieldValidator<T>):
  FieldValidator<T> & WithShouldMarkFieldAsRequired
{
  return new class
  {
    public validate(field: DocField<T>): string[]
    {
      return cond() ? another.validate(field) : [];
    }

    public shouldMarkFieldAsRequired()
    {
      return cond() && IsWithShouldMarkFieldAsRequired(another) && another.shouldMarkFieldAsRequired();
    }
  }();
}
