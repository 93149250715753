import { ListElement } from '../../common/doc/FormDoc';
import { DocTextField } from '../../common/doc/fields/DocTextField';
import { DocBooleanField } from '../../common/doc/fields/DocBooleanField';
import { MapConverter } from '../../common/converter/ConvEnv';
import { envBrandPZlow, envBrandWDB } from '../Const';

/**
 * W zależności od brandingu jest różna ścieżka strony frontOffice
 * Tam, gdzie branding powoduje zmianę treści zgody -- dodawana jest wersja tej zgody po brandingu
 * żeby po stronie serwera była zgoda między kodem a treścią
 */
export const PublicBucketUrl =
  envBrandWDB
    ? 'https://ubezpieczeniadirect.pl/docs/'
    : envBrandPZlow
      ? 'https://ubezpieczonedzieci.com/docs/'
      : '/';

export const WDBInfoPath = `common/2024/Informacja o brokerze_PIB Broker.pdf`;
export const WDBInfoPathUrl = `common/2024/${encodeURI('Informacja o brokerze_PIB Broker.pdf')}`;
export const PrivacyPolicyPath = `common/2022-06-27/Polityka.pdf`;
export const PrivacyPolicy24Path = `common/2024/Polityka Prywatności.pdf`;
export const PrivacyPolicy24PathUrl = `common/2024/${encodeURI('Polityka Prywatności.pdf')}`;
export const RulesPath = `common/2022-06-27/Regulamin.pdf`;
export const Rules24Path = `common/2024/Regulamin.pdf`;
export const WDBLicensePath = `common/2024/Zezwolenie.pdf`;
export const InfoPath = `common/2024/Klauzula informacyjna_PIB Broker.pdf`;
export const InfoPathUrl = `common/2024/${encodeURI('Klauzula informacyjna_PIB Broker.pdf')}`;
export const FundationInfoPath = `common/Fundacja_klauzula_informacyjna.pdf`;
export const FundationInfo24Path = `common/2024/Klauzula informacyjna_Fundacja.pdf`;
export const InterRiskInfo24Path = `common/2024/Klauzula informacyjna_InterRisk.pdf`;
export const InterRiskInfo24PathUrl = `common/2024/${encodeURI('Klauzula informacyjna_InterRisk.pdf')}`;
export const OWUEduPlusPath = `accident-policy/docs/2022/OWU_Edu_Plus.pdf`;
export const OWUEduPlus24Path = `accident-policy/docs/2024/OWU EDU Plus_Karta Produktu.pdf`;
export const OWUEduPlus24PathUrl = `accident-policy/docs/2024/${encodeURI('OWU EDU Plus_Karta Produktu.pdf')}`;
export const ProductCardPath = `accident-policy/docs/Karta_Produktu_Edu_Plus.pdf`;
export const AmendmentsPath = `accident-policy/docs/2024/Postanowienia dodatkowe i odmienne od OWU.pdf`;
export const AmendmentsPathUrl = `accident-policy/docs/2024/${encodeURI('Postanowienia dodatkowe i odmienne od OWU.pdf')}`;
export const HateStopPath = `accident-policy/docs/2022/HEJT_STOP.pdf`;
export const HateStop24Path = `accident-policy/docs/2024/Ulotka_Hejt Stop.pdf`;
export const ClaimInfoPath = `accident-policy/docs/Instrukcja_zgłoszenia_roszczenia.pdf`;
export const ClaimInfo2Path = `accident-policy/docs/proces_zglaszania_roszczenia_web.pdf`;
export const IRRODOPath = `common/Obowiązek_Informacyjny_Administratora_Danych_Osobowych.pdf`;
export const ClaimFormPath = `accident-policy/docs/2024/${encodeURI('Druk zgłoszenia roszczenia EDU PLUS 2024 pib.pdf')}`;


export const CsWDBRulesCode = 'WDBRULES';
export const CsWDBRulesText = `Zapoznałem się z treścią <a target="_blank" href="${PublicBucketUrl}${RulesPath}">Regulaminu</a> portalu i akceptuję jego postanowienia.`;
export const CsWDBRulesTitle = CsWDBRulesText;

export const CsWDBPrivacyCode = 'WDBPRIVACY';
export const CsWDBPrivacyText = `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicyPath}">treścią polityki prywatności</a>.`;
export const CsWDBPrivacyTitle = CsWDBPrivacyText;

export const CsWDBMarketingCode = 'WDBMARKETING';
export const CsWDBMarketingTitle = 'Wyrażam zgodę na przetwarzanie przez WDB S.A. moich danych osobowych w celu wykonywania na moją rzecz działań marketingowych';
export const CsWDBMarketingText = 'Wyrażam zgodę na przetwarzanie przez WDB S.A. moich danych osobowych w celu wykonywania na moją rzecz działań marketingowych polegających na przygotowywaniu ofert czy przedstawianiu produktów ubezpieczeniowych oraz produktów i usług własnych bądź promowanych przez spółkę w ramach współpracy.';

export const CsWDBCommercialCode = 'WDBCOMMERCIAL';
export const CsWDBCommercialTitle = 'Wyrażam zgodę na przesyłanie na mój adres e-mail oraz adres korespondencyjny informacji handlowych przez WDB S. A.';
export const CsWDBCommercialText = 'Wyrażam zgodę na przesyłanie na mój adres e-mail oraz adres korespondencyjny informacji handlowych przez WDB S. A. zgodnie z art. 10 ust. 2 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t. j. Dz. U. z 2013 r., poz. 1422).';

export const CsWDBTelemarketingCode = 'WDBTELEMARKETING';
export const CsWDBTelemarketingTitle = 'Wyrażam zgodę na marketing bezpośredni przy użyciu telekomunikacyjnych urządzeń końcowych';
export const CsWDBTelemarketingText = 'Wyrażam zgodę na marketing bezpośredni przy użyciu telekomunikacyjnych urządzeń końcowych (np. telefon, mail, fax) oraz automatycznych systemów wywołujących przez WDB S. A. zgodnie z art. 172 ust. 1 ustawy z dnia 16 lipca 2004 roku – Prawo telekomunikacyjne (t. j. Dz. U. z 2014 r., poz. 243).';

export const CsIROWUCode = 'IROWU';
export const CsIROWUTitle = `Wyrażam zgodę na doręczenie <a target="_blank" href="${PublicBucketUrl}${OWUEduPlusPath}">Ogólnych Warunków Ubezpieczenia EDU PLUS</a>`;
export const CsIROWUText = CsIROWUTitle + ' zatwierdzonych uchwałą nr 01/03/03/2020 Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 03.03.2020r. („OWU”), Postanowień Dodatkowymi i Odmiennych od OWU oraz informacji, o których mowa w art. 17 ust. 1 ustawy o działalności ubezpieczeniowej i reasekuracyjnej w formie plików PDF załączonych do e-maila przesłanego na wskazany poniżej adres e-mail';

export const CsIROWU2Code = 'IROWU2';
export const CsIROWU2Title = 'Oświadczam, że otrzymałem(am) i zapoznałem(am) się z warunkami umowy ubezpieczenia, w tym z Ogólnymi Warunkami Ubezpieczenia EDU PLUS';
export const CsIROWU2Text = 'Oświadczam, że otrzymałem(am) i zapoznałem(am) się z warunkami umowy ubezpieczenia, w tym z Ogólnymi Warunkami Ubezpieczenia EDU PLUS zatwierdzonymi uchwałą nr 01/03/03/2020 Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 03.03.2020r. ("OWU"), Postanowieniami Dodatkowymi i Odmiennymi od OWU, informacjami o których mowa w art. 17 ust. 1 ustawy o działalności ubezpieczeniowej i reasekuracyjnej, treścią dokumentu Obowiązek Informacyjny Administratora Danych Osobowych, informacją dotyczącą możliwości i procedury złożenia i rozpatrzenia skarg i reklamacji, organu właściwego do ich rozpatrzenia oraz pozasądowego rozpatrywania sporów.';

export const CsIRShareCode = 'IRSHARE';
export const CsIRShareTitle = 'Wyrażam zgodę na udostępnianie – na żądanie InterRisk TU S.A. Vienna Insurance Group – moich/mojego dziecka danych osobowych';
export const CsIRShareText = 'Wyrażam zgodę na udostępnianie – na żądanie InterRisk TU S.A. Vienna Insurance Group – moich/mojego dziecka danych osobowych przetwarzanych przez inne zakłady ubezpieczeń w zakresie potrzebnym do oceny ryzyka ubezpieczeniowego i weryfikacji podanych przeze mnie danych, ustalenia prawa do świadczenia z zawartej umowy ubezpieczenia i wysokości tego świadczenia, a także do udzielenia posiadanych informacji o przyczynie mojej/mojego dziecka śmierci lub informacji niezbędnych do ustalenia prawa uprawnionego z umowy ubezpieczenia do świadczenia i jego wysokości.';

export const CsIRNFZCode = 'IRNFZ';
export const CsIRNFZTitle = 'Wyrażam zgodę na uzyskiwanie przez InterRisk TU S.A. Vienna Insurance Group od Narodowego Funduszu Zdrowia informacji o nazwach i adresach świadczeniodawców';
export const CsIRNFZText = 'Wyrażam zgodę na uzyskiwanie przez InterRisk TU S.A. Vienna Insurance Group od Narodowego Funduszu Zdrowia informacji o nazwach i adresach świadczeniodawców, którzy udzielą mi/mojemu dziecku świadczeń opieki zdrowotnej w związku z wypadkiem lub zdarzeniem losowym będącym podstawą ustalenia odpowiedzialności InterRisk TU S.A. Vienna Insurance Group oraz wysokości odszkodowania lub świadczenia.';

export const CsIRDokMedCode = 'IRDOKMED';
export const CsIRDokMedTitle = 'Wyrażam zgodę na uzyskiwanie przez InterRisk TU S.A. Vienna Insurance Group od podmiotów wykonujących działalność leczniczą w rozumieniu powszechnie obowiązujących przepisów, które udzielały lub będą udzielać mi/mojemu dziecku świadczeń zdrowotnych, informacji';
export const CsIRDokMedText = 'Wyrażam zgodę na uzyskiwanie przez InterRisk TU S.A. Vienna Insurance Group od podmiotów wykonujących działalność leczniczą w rozumieniu powszechnie obowiązujących przepisów, które udzielały lub będą udzielać mi/mojemu dziecku świadczeń zdrowotnych, informacji o okolicznościach związanych z oceną ryzyka ubezpieczeniowego i weryfikacją podanych przeze mnie danych o stanie mojego/mojego dziecka zdrowia, ustaleniem prawa do świadczenia z zawartej umowy ubezpieczenia i wysokością tego świadczenia, w zakresie określonym w ustawie o działalności ubezpieczeniowej i reasekuracyjnej. Równocześnie wyrażam zgodę na udostępnianie InterRisk TU S.A. Vienna Insurance Group przez wyżej wymienione podmioty dokumentacji medycznej.';

export const CsIRMarketingCode = 'IRMARKETING';
export const CsIRMarketingTitle = 'Wyrażam zgodę na otrzymywanie od InterRisk TU S.A. Vienna Insurance Group z siedzibą w Warszawie informacji handlowo-marketingowych';
export const CsIRMarketingText = 'Wyrażam zgodę na otrzymywanie od InterRisk TU S.A. Vienna Insurance Group z siedzibą w Warszawie informacji handlowo-marketingowych z użyciem telekomunikacyjnych urządzeń końcowych tzw. automatycznych systemów wywołujących; wskazuję jako formę kontaktu: wiadomości elektroniczne (podany przeze mnie adres e - mail)';

export const CsIRMarketing2Code = 'IRMARKETING2';
export const CsIRMarketing2Title = 'Wyrażam zgodę na przetwarzanie przez InterRisk TU S.A. Vienna Insurance Group z siedzibą w Warszawie, ul. Noakowskiego 22, w celu marketingu własnych produktów lub usług oraz dla potrzeb przygotowania dla mnie odpowiedniej oferty';
export const CsIRMarketing2Text = 'Wyrażam zgodę na przetwarzanie przez InterRisk TU S.A. Vienna Insurance Group z siedzibą w Warszawie, ul. Noakowskiego 22, w celu marketingu własnych produktów lub usług oraz dla potrzeb przygotowania dla mnie odpowiedniej oferty, co obejmuje także profilowanie w celu poznania moich potrzeb i preferencji, moich danych osobowych po zakończeniu umowy w zakresie obejmującym moje dane identyfikacyjne, kontaktowe oraz dane dotyczące zawartych przeze mnie umów i ich realizacji, a także inne dane zebrane związane ze świadczeniem mi usług ubezpieczeniowych lub zbierane w związku z zapytaniami lub kontaktami dotyczącymi takich usług.';


// 2022
export const CsWDBRules2Code = 'WDBRULES2';
export const CsWDBRules2Text = `Zapoznałem/am się z treścią <a target="_blank" href="${PublicBucketUrl}${RulesPath}">Regulaminu niniejszej strony internetowej</a> i akceptuję jego postanowienia.`;
export const CsWDBRules2Title = CsWDBRules2Text;

export const CsWDBRules2PZlowCode = 'WDBRULES2pzlow';
export const CsWDBRules2PZlowText = `Zapoznałem/am się z treścią <a target="_blank" href="${PublicBucketUrl}${RulesPath}">Regulaminu niniejszej strony internetowej</a> i akceptuję jego postanowienia.`;
export const CsWDBRules2PZlowTitle = CsWDBRules2PZlowText;

export const CsWDBPrivacy2Code = 'WDBPRIVACY2';
export const CsWDBPrivacy2Text = `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicyPath}">treścią Polityki Prywatności WDB S.A.</a>.`;
export const CsWDBPrivacy2Title =
  `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicyPath}">treścią Polityki Prywatności WDB S.A.</a> dot.` +
  `przetwarzania danych osobowych za pośrednictwem strony internetowej: www.ubezpieczeniadirect.pl, w tym zapoznałem z jej treścią osobę w imieniu i na rzecz której działam.`;

export const CsWDBPrivacy2PZlowCode = 'WDBPRIVACY2pzlow';
export const CsWDBPrivacy2PZlowText = `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicyPath}">treścią Polityki Prywatności WDB S.A.</a>.`;
export const CsWDBPrivacy2PZlowTitle =
  `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicyPath}">treścią Polityki Prywatności WDB S.A.</a> dot.` +
  `przetwarzania danych osobowych za pośrednictwem strony internetowej: www.ubezpieczeniadirect.pl, w tym zapoznałem z jej treścią osobę w imieniu i na rzecz której działam.`;

export const CsWDBCommercial2Code = 'WDBCOMMERCIAL2';
export const CsWDBCommercial2Title = 'Wyrażam zgodę na przesyłanie na mój adres e-mail oraz adres korespondencyjny informacji handlowych przez WDB S.A.';
export const CsWDBCommercial2Text = 'Wyrażam zgodę na przesyłanie na mój adres e-mail informacji handlowych przez WDB S.A. w ramach podejmowanych przez WDB S.A. działań marketingu bezpośredniego';

export const CsWDBTelemarketing2Code = 'WDBTELEMARKETING2';
export const CsWDBTelemarketing2Title = 'Wyrażam zgodę na marketing bezpośredni przy użyciu telekomunikacyjnych urządzeń końcowych';
export const CsWDBTelemarketing2Text = 'Wyrażam zgodę na marketing bezpośredni przy użyciu telekomunikacyjnych urządzeń końcowych (np. telefon, e-mail, fax) oraz automatycznych systemów wywołujących przez WDB S.A.';

export const CsIROWUSend2Code = 'IROWUSEND2';
export const CsIROWUSend2Title = `Wyrażam zgodę na doręczenie mi <a target="_blank" href="${PublicBucketUrl}${OWUEduPlusPath}">Ogólnych Warunków Ubezpieczenia EDU PLUS</a>`;
export const CsIROWUSend2Text = CsIROWUSend2Title + ' zatwierdzonych uchwałą nr  01/25/03/2022 Zarządu InterRisk TU S.A. Vienna Insurance Group  z dnia 25.03.2022r. („OWU”), Postanowień Dodatkowych i Odmiennych od OWU oraz informacji, o których mowa w art. 17 ust. 1 ustawy o działalności ubezpieczeniowej i reasekuracyjnej w formie plików PDF załączonych do e-maila przesłanego na wskazany przeze mnie adres e-mail.';

export const CsIROWUSend2PZlowCode = 'IROWUSEND2pzlow';
export const CsIROWUSend2PZlowTitle = `Wyrażam zgodę na doręczenie mi <a target="_blank" href="${PublicBucketUrl}${OWUEduPlusPath}">Ogólnych Warunków Ubezpieczenia EDU PLUS</a>`;
export const CsIROWUSend2PZlowText = CsIROWUSend2PZlowTitle + ' zatwierdzonych uchwałą nr  01/25/03/2022 Zarządu InterRisk TU S.A. Vienna Insurance Group  z dnia 25.03.2022r. („OWU”), Postanowień Dodatkowych i Odmiennych od OWU oraz informacji, o których mowa w art. 17 ust. 1 ustawy o działalności ubezpieczeniowej i reasekuracyjnej w formie plików PDF załączonych do e-maila przesłanego na wskazany przeze mnie adres e-mail.';

export const CsIROWUAccept2Code = 'IROWUACCEPT2';
export const CsIROWUAccept2Title = 'Oświadczam, że otrzymałem(am) i zapoznałem(am) się z warunkami umowy ubezpieczenia, w tym z Ogólnymi Warunkami Ubezpieczenia EDU PLUS';
export const CsIROWUAccept2Text = 'Oświadczam, że otrzymałem(am) i zapoznałem(am) się z warunkami umowy ubezpieczenia, w tym z Ogólnymi Warunkami Ubezpieczenia EDU PLUS zatwierdzonymi uchwałą  nr 01/25/03/2022 Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 25.03.2022r. ("OWU"), Postanowieniami Dodatkowymi i Odmiennymi od OWU, informacjami o których mowa w art. 17 ust. 1 ustawy o działalności ubezpieczeniowej i reasekuracyjnej, treścią dokumentu Obowiązek Informacyjny Administratora Danych Osobowych, informacją dotyczącą możliwości i procedury złożenia i rozpatrzenia skarg i reklamacji, organu właściwego do ich rozpatrzenia oraz pozasądowego rozpatrywania sporów.';

// 2024
export const CsIROWUSend24Code = 'IROWUSEND24';
export const CsIROWUSend24Title = `Wyrażam zgodę na doręczenie mi <a target="_blank" href="${PublicBucketUrl}${OWUEduPlus24PathUrl}">Ogólnych Warunków Ubezpieczenia EDU PLUS</a>`;
export const CsIROWUSend24Text = CsIROWUSend24Title + ' zatwierdzonych uchwałą nr 01/27/03/2024 Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 27.03.2024 r. („OWU”), Postanowień Dodatkowych i Odmiennych od OWU oraz informacji, o których mowa w art. 17 ust. 1 ustawy o działalności ubezpieczeniowej i reasekuracyjnej w formie plików PDF załączonych do e-maila przesłanego na wskazany przeze mnie adres e-mail.';

export const CsIROWUSend24PZlowCode = 'IROWUSEND24pzlow';
export const CsIROWUSend24PZlowTitle = `Wyrażam zgodę na doręczenie mi <a target="_blank" href="${PublicBucketUrl}${OWUEduPlus24PathUrl}">Ogólnych Warunków Ubezpieczenia EDU PLUS</a>`;
export const CsIROWUSend24PZlowText = CsIROWUSend24PZlowTitle + ' zatwierdzonych uchwałą nr 01/27/03/2024 Zarządu InterRisk TU S.A. Vienna Insurance Group z dnia 27.03.2024 r. („OWU”), Postanowień Dodatkowych i Odmiennych od OWU oraz informacji, o których mowa w art. 17 ust. 1 ustawy o działalności ubezpieczeniowej i reasekuracyjnej w formie plików PDF załączonych do e-maila przesłanego na wskazany przeze mnie adres e-mail.';

export const CsWDBRules24Code = 'WDBRULES24';
export const CsWDBRules24Text = `Zapoznałem/am się z treścią <a target="_blank" href="${PublicBucketUrl}${Rules24Path}">Regulaminu niniejszej strony internetowej</a> i akceptuję jego postanowienia.`;
export const CsWDBRules24Title = CsWDBRules24Text;

export const CsWDBRules24PZlowCode = 'WDBRULES2pzlow';
export const CsWDBRules24PZlowText = `Zapoznałem/am się z treścią <a target="_blank" href="${PublicBucketUrl}${Rules24Path}">Regulaminu niniejszej strony internetowej</a> i akceptuję jego postanowienia.`;
export const CsWDBRules24PZlowTitle = CsWDBRules24PZlowText;

export const CsWDBPrivacy24Code = 'WDBPRIVACY24';
export const CsWDBPrivacy24Text = `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicy24PathUrl}">treścią Polityki Prywatności PIB Broker S.A.</a>.`;
export const CsWDBPrivacy24Title =
  `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicy24PathUrl}">treścią Polityki Prywatności PIB Broker S.A.</a> dot.` +
  `przetwarzania danych osobowych za pośrednictwem strony internetowej: www.ubezpieczeniadirect.pl, w tym zapoznałem z jej treścią osobę w imieniu i na rzecz której działam.`;

export const CsWDBPrivacy24PZlowCode = 'WDBPRIVACY24pzlow';
export const CsWDBPrivacy24PZlowText = `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicy24PathUrl}">treścią Polityki Prywatności PIB Broker S.A.</a>.`;
export const CsWDBPrivacy24PZlowTitle =
  `Działając w imieniu własnym oraz osób zgłaszanych przeze mnie do ubezpieczenia oświadczam, że zapoznałem się z <a target="_blank" href="${PublicBucketUrl}${PrivacyPolicy24PathUrl}">treścią Polityki Prywatności PIB Broker S.A.</a> dot.` +
  `przetwarzania danych osobowych za pośrednictwem strony internetowej: www.ubezpieczeniadirect.pl, w tym zapoznałem z jej treścią osobę w imieniu i na rzecz której działam.`;

export const CsWDBCommercial24Code = 'WDBCOMMERCIAL24';
export const CsWDBCommercial24Title = 'Wyrażam zgodę na przesyłanie na mój adres e-mail oraz adres korespondencyjny informacji handlowych przez PIB Broker S.A.';
export const CsWDBCommercial24Text = 'Wyrażam zgodę na przesyłanie na mój adres e-mail informacji handlowych przez PIB Broker S.A. w ramach podejmowanych przez PIB Broker S.A. działań marketingu bezpośredniego';

export const CsWDBTelemarketing24Code = 'WDBTELEMARKETING24';
export const CsWDBTelemarketing24Title = 'Wyrażam zgodę na marketing bezpośredni przy użyciu telekomunikacyjnych urządzeń końcowych';
export const CsWDBTelemarketing24Text = 'Wyrażam zgodę na marketing bezpośredni przy użyciu telekomunikacyjnych urządzeń końcowych (np. telefon, e-mail, fax) oraz automatycznych systemów wywołujących przez PIB Broker S.A.';


export class Consent extends ListElement
{
  public readonly text = new DocTextField('text', 'Treść zgody', this);
  public readonly isGiven = new DocBooleanField('isGiven', 'Czy zgoda wyrażona', this);
}

export interface ConsentConvVal
{
  title: string;
  text: string;
}

export const ConsentConv: MapConverter<ConsentConvVal> = new MapConverter([
  {
    key: CsWDBRulesCode,
    value: { title: CsWDBRulesTitle, text: CsWDBRulesText },
    withdrawn: false
  },
  {
    key: CsWDBRules24Code,
    value: { title: CsWDBRules24Title, text: CsWDBRules24Text },
    withdrawn: false
  },
  {
    key: CsWDBRules24PZlowCode,
    value: { title: CsWDBRules24PZlowTitle, text: CsWDBRules24PZlowText },
    withdrawn: false
  },
  {
    key: CsWDBPrivacyCode,
    value: { title: CsWDBPrivacyTitle, text: CsWDBPrivacyText },
    withdrawn: false
  },
  {
    key: CsWDBPrivacy2Code,
    value: { title: CsWDBPrivacy2Title, text: CsWDBPrivacy2Text },
    withdrawn: true
  },
  {
    key: CsWDBPrivacy24Code,
    value: { title: CsWDBPrivacy24Title, text: CsWDBPrivacy24Text },
    withdrawn: true
  },
  {
    key: CsWDBPrivacy2PZlowCode,
    value: { title: CsWDBPrivacy2PZlowTitle, text: CsWDBPrivacy2PZlowText },
    withdrawn: true
  },
  {
    key: CsWDBPrivacy24PZlowCode,
    value: { title: CsWDBPrivacy24PZlowTitle, text: CsWDBPrivacy24PZlowText },
    withdrawn: true
  },
  {
    key: CsWDBMarketingCode,
    value: { title: CsWDBMarketingTitle, text: CsWDBMarketingText },
    withdrawn: false
  },
  {
    key: CsWDBCommercialCode,
    value: { title: CsWDBCommercialTitle, text: CsWDBCommercialText },
    withdrawn: false
  },
  {
    key: CsWDBCommercial2Code,
    value: { title: CsWDBCommercial2Title, text: CsWDBCommercial2Text },
    withdrawn: true
  },
  {
    key: CsWDBCommercial24Code,
    value: { title: CsWDBCommercial24Title, text: CsWDBCommercial24Text },
    withdrawn: false
  },
  {
    key: CsWDBTelemarketingCode,
    value: { title: CsWDBTelemarketingTitle, text: CsWDBTelemarketingText },
    withdrawn: false
  },
  {
    key: CsWDBTelemarketing2Code,
    value: { title: CsWDBTelemarketing2Title, text: CsWDBTelemarketing2Text },
    withdrawn: true
  },
  {
    key: CsWDBTelemarketing24Code,
    value: { title: CsWDBTelemarketing24Title, text: CsWDBTelemarketing24Text },
    withdrawn: true
  },
  {
    key: CsIROWUCode,
    value: { title: CsIROWUTitle, text: CsIROWUText },
    withdrawn: false
  },
  {
    key: CsIROWU2Code,
    value: { title: CsIROWU2Title, text: CsIROWU2Text },
    withdrawn: false
  },
  {
    key: CsIROWUSend2Code,
    value: { title: CsIROWUSend2Title, text: CsIROWUSend2Text },
    withdrawn: true
  },
  {
    key: CsIROWUSend24Code,
    value: { title: CsIROWUSend24Title, text: CsIROWUSend24Text },
    withdrawn: false
  },
  {
    key: CsIROWUSend2PZlowCode,
    value: { title: CsIROWUSend2PZlowTitle, text: CsIROWUSend2PZlowText },
    withdrawn: true
  },
  {
    key: CsIROWUSend24PZlowCode,
    value: { title: CsIROWUSend24PZlowTitle, text: CsIROWUSend24PZlowText },
    withdrawn: false
  },
  {
    key: CsIROWUAccept2Code,
    value: { title: CsIROWUAccept2Title, text: CsIROWUAccept2Text },
    withdrawn: false
  },
  {
    key: CsIRShareCode,
    value: { title: CsIRShareTitle, text: CsIRShareText },
    withdrawn: false
  },
  {
    key: CsIRNFZCode,
    value: { title: CsIRNFZTitle, text: CsIRNFZText },
    withdrawn: false
  },
  {
    key: CsIRDokMedCode,
    value: { title: CsIRDokMedTitle, text: CsIRDokMedText },
    withdrawn: false
  },
  {
    key: CsIRMarketingCode,
    value: { title: CsIRMarketingTitle, text: CsIRMarketingText },
    withdrawn: false
  },
  {
    key: CsIRMarketing2Code,
    value: { title: CsIRMarketing2Title, text: CsIRMarketing2Text },
    withdrawn: false
  }
]);
