import { ObjectStateSource } from '../common/state/ObjectStateSource';
import React, { useContext } from 'react';
import { AccidentPolicy } from '../model/policy/AccidentPolicy';

export interface GlobalState
{
  policyDoc: AccidentPolicy;
}

export const GlobalStateContext = React.createContext<GlobalState>(
  {
    policyDoc: new AccidentPolicy(new ObjectStateSource({ global: true }))
  }
);

export function usePolicy()
{
  const gctx = useContext(GlobalStateContext);
  const policy = gctx.policyDoc;
  return policy;
}
