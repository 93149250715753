import { DocField, FieldContainer } from '../FormDoc';

export class DocEnumField<Enum> extends DocField<Enum> {

  public constructor(
    fieldName: string,
    fieldLabel: string,
    datasource: FieldContainer
  )
  {
    super(fieldName, fieldLabel, datasource);
  }
}
