
import { DocField, FieldContainer } from '../FormDoc';
import { LocalDate } from '../../type/LocalDate';

/** Data oderwana od strefy czasowej */
export class DocLocalDateField extends DocField<LocalDate> {

  public constructor(
    fieldName: string,
    fieldLabel: string,
    datasource: FieldContainer
  )
  {
    super(fieldName, fieldLabel, datasource);
  }

  public eqSaved(): boolean
  {
    const saved = this.savedValue;
    const current = this.getValue();

    if (current)
    {
      return current.eq(saved);
    }

    return saved === undefined || saved === null;
  }

  protected valueToJson(data: LocalDate | undefined): number | undefined
  {
    return data ? data.toJSON() : undefined;
  }

  protected jsonToValue(json: any): LocalDate | undefined
  {
    return LocalDate.fromJSON(json);
  }

}
