import { FieldValidator, DocField } from '../FormDoc';
import { StringUtils } from '../../utl/StringUtils';

export class REGONValidator implements FieldValidator<string> {

  public validate(field: DocField<string>): string[]
  {
    const regonMsg = field.isControlEmpty() ? '' : StringUtils.regonOK(field.getControlValue() || '');
    return (regonMsg === '') ? [] : [regonMsg];
  }

}

export class NIPValidator implements FieldValidator<string> {

  public validate(field: DocField<string>): string[]
  {
    const nipMsg = field.isControlEmpty() ? '' : StringUtils.nipOK(field.getControlValue() || '');
    return (nipMsg === '') ? [] : [nipMsg];
  }

}

export class PESELValidator implements FieldValidator<string>
{
  public constructor(protected customErrorMsg?: string)
  { }

  public validate(field: DocField<string>): string[]
  {
    const peselMsg = field.isControlEmpty() ? '' : StringUtils.peselOK(field.getControlValue() || '');
    return (peselMsg === '') ? [] : [this.customErrorMsg || peselMsg];
  }
}

export class PLIdentValidator implements FieldValidator<string> {

  public validate(field: DocField<string>): string[]
  {
    let identMsg = '';
    const val = field.getControlValue() || '';
    if (val !== '')
    {
      identMsg = 'Podany numer nie jest poprawnym numerem NIP, REGON lub PESEL';
      if (val.length === 9)
      {
        identMsg = StringUtils.regonOK(val);
      }
      if (val.length === 10)
      {
        identMsg = StringUtils.nipOK(val);
      }
      if (val.length === 11)
      {
        identMsg = StringUtils.peselOK(val);
      }
      if (val.length === 14)
      {
        identMsg = StringUtils.regonOK(val);
      }
    }
    return (identMsg === '') ? [] : [identMsg];
  }
}
