import { LocalDate } from '../type/LocalDate';

export abstract class StringUtils
{
  public static fillLeftStr(base: string | number, width: number, filler: string): string
  {
    filler = filler || '0';
    base = base + '';
    return base.length >= width
      ? base
      : new Array(width - base.length + 1).join(filler) + base;
  }

  public static regonOK(regon: string): string
  {
    const rex9 = new RegExp('^\\d{9}$');
    if (rex9.test(regon))
    {
      return StringUtils.regon9OK(regon);
    }
    else
    {
      const rex14 = new RegExp('^\\d{14}$');
      if (rex14.test(regon))
      {
        return StringUtils.regon14OK(regon);
      }
      else
      {
        return 'REGON musi składać się z 9 lub 14 cyfr';
      }
    }
  }

  public static regon9OK(regon: string): string
  {
    const rex = new RegExp('^\\d{9}$');
    if (!rex.test(regon))
    {
      return 'REGON musi składać się z 9 cyfr';
    }
    else
    {
      const w = '89234567';
      let k = 0;
      for (let i = 0; i < 8; i++)
      {
        k = k + Number(regon.substring(i, i + 1)) * Number(w.substring(i, i + 1));
      }
      k = (k % 11) % 10;
      if (k === Number(regon.substring(8, 9)))
      {
        return '';
      }
      else
      {
        return 'Błąd sumy kontrolnej REGON';
      }
    }
  }

  public static regon14OK(regon: string): string
  {
    const rex = new RegExp('^\\d{14}$');
    if (!rex.test(regon))
    {
      return 'REGON musi składać się z 14 cyfr';
    }
    else
    {
      const w = '2485097361248';
      let k = 0;
      for (let i = 0; i < 13; i++)
      {
        k = k + Number(regon.substring(i, i + 1)) * Number(w.substring(i, i + 1));
      }
      k = (k % 11) % 10;
      if (k === Number(regon.substring(13, 14)))
      {
        return '';
      }
      else
      {
        return 'Błąd sumy kontrolnej REGON';
      }
    }
  }

  public static nipOK(nip: string): string
  {
    const rex = new RegExp('^\\d{10}$');
    if (!rex.test(nip))
    {
      return 'NIP musi składać się z 10 cyfr';
    }
    else
    {
      const w = '657234567';
      let k = 0;
      for (let i = 0; i < 9; i++)
      {
        k = k + (Number(nip.substring(i, i + 1)) * Number(w.substring(i, i + 1)));
      }
      k = (k % 11) % 10;
      if (k === Number(nip.substring(9, 10)))
      {
        return '';
      }
      else
      {
        return 'Błąd sumy kontrolnej NIP';
      }
    }
  }

  public static peselOK(pesel: string): string
  {
    const rex = new RegExp('^\\d{11}$');
    if (!rex.test(pesel))
    {
      return 'PESEL musi składać się z 11 cyfr';
    }
    else
    {
      const w = '13791379131';
      let k = 0;
      for (let i = 0; i < 11; i++)
      {
        k = k + (Number(pesel.substring(i, i + 1)) * Number(w.substring(i, i + 1)));
      }
      if ((k > 0) && ((k % 10) === 0))
      {
        return '';
      }
      else
      {
        return 'Błąd sumy kontrolnej PESEL';
      }
    }
  }

  public static krsOK(krs: string): string
  {
    const rex = new RegExp('^\\d{10}$');
    if (!rex.test(krs))
    {
      return 'KRS musi mieć 10 cyfr';
    }
    else
    {
      return '';
    }
  }

  public static kontoOK(konto: string): string
  {
    const rex = new RegExp('^\\d{26}$');
    if (!rex.test(konto))
    {
      return 'Numer konta musi składać się z wyłącznie 26 cyfr';
    }
    else
    {
      const tmp = konto.substring(2) + '2521' + konto.substring(0, 2);
      const bigInt = require('big-integer');
      if (bigInt(tmp).mod(97).valueOf() === 1)
      {
        return '';
      }
      else
      {
        return 'Błąd sumy kontrolnej numeru konta';
      }
    }
  }

  public static sepConcat(sep: string, ...strs: Array<string | undefined>)
  {
    return strs.filter((s) => (s !== undefined) && (s.length > 0)).join(sep);
  }

  public static concat(...strs: Array<string | undefined>)
  {
    return this.sepConcat(' ', ...strs);
  }

  public static slownie(num: number): string
  {
    const ls =
      ['', 'sto', 'dwieście', 'trzysta', 'czterysta', 'pięćset', 'sześćset', 'siedemset', 'osiemset', 'dziewięćset'];
    const ld =
      ['', 'dziesięć', 'dwadzieścia', 'trzydzieści', 'czterdzieści', 'pięćdziesiąt', 'sześćdziesiąt', 'siedemdziesiąt',
        'osiemdziesiąt', 'dziewięćdziesiąt'];
    const ln =
      ['dziesięć', 'jedenaście', 'dwanaście', 'trzynaście', 'czternaście', 'piętnaście',
        'szesnaście', 'siedemnaście', 'osiemnaście', 'dziewiętnaście'];
    const lj =
      ['', 'jeden', 'dwa', 'trzy', 'cztery', 'pięć', 'sześć', 'siedem', 'osiem', 'dziewięć'];
    const miliony = ['milion', 'miliony', 'milionów'];
    const tysiace = ['tysiąc', 'tysiące', 'tysięcy'];
    const jednostki = ['', '', ''];
    const concsp = (s1: string, s2: string) =>
    {
      if (s1 === '') { return s2; }
      else if (s2 === '') { return s1; }
      else { return s1 + ' ' + s2; }
    };
    const dziesiatki = (n: number) =>
    {
      if (n === 0) { return ''; }
      else if (Math.floor(n / 10) === 1) { return ln[n % 10]; }
      else { return concsp(ld[Math.floor(n / 10)], lj[n % 10]); }
    };
    const setki = (n: number) =>
    {
      return concsp(ls[Math.floor(n / 100)], dziesiatki(n % 100));
    };
    const wiele = (tab: string[], n: number) =>
    {
      if (n === 0) { return ''; }
      else if (n === 1) { return concsp(setki(n), tab[0]); }
      else if ([12, 13, 14].indexOf(n % 100) !== -1) { return concsp(setki(n), tab[2]); }
      else if ([2, 3, 4].indexOf(n % 10) !== -1) { return concsp(setki(n), tab[1]); }
      else { return concsp(setki(n), tab[2]); }
    };
    if (num === 0) { return 'zero'; }
    else if (num < 0) { return 'minus ' + StringUtils.slownie(-num); }
    else if (num > 999999999) { return 'za duża liczba do pokazania słownie'; }
    else
    {
      return concsp(wiele(miliony, Math.floor(num / 1000000)),
        concsp(wiele(tysiace, Math.floor((num % 1000000) / 1000)),
          wiele(jednostki, num % 1000)));
    }
  }

  /**
   * Odmiana rzeczownika "np złoty/złote/złotych"
   */
  public static slownieWiele(num: number, f1: string, f2: string, f5: string)
  {
    if (num === 1)
    {
      return f1;
    }
    else if ([2, 3, 4].indexOf(num % 10) !== -1)
    {
      return f2;
    }
    else
    {
      return f5;
    }
  }

  public static peselDataUr(pesel: string | undefined): LocalDate | undefined
  {
    if (!pesel)
    {
      return undefined;
    }

    let rr = Number(pesel.substring(0, 2));
    let mm = Number(pesel.substring(2, 4));
    const dd = Number(pesel.substring(4, 6));
    if (mm < 13)
    {
      rr = rr + 1900;
    }
    else if (mm < 33)
    {
      rr = rr + 2000;
      mm = mm - 20;
    }
    else if (mm < 53)
    {
      rr = rr + 2100;
      mm = mm - 40;
    }
    else if (mm < 73)
    {
      rr = rr + 2200;
      mm = mm - 60;
    }
    else
    {
      rr = rr + 1800;
      mm = mm - 80;
    }
    return new LocalDate(rr, mm, dd);
  }

}
