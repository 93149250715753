import stringify from 'safe-stable-stringify';

export interface IStateSource
{
  // returs state of the App in terms of the root component
  getState<T>(name: string): T | undefined;
  // sets a new state and propagates it to the root component
  setNextState<T>(callback: (stateMap: any) => void): void;
  // returns the most current state of the App, may not be reflected yet in the root component
  onLive<T>(actionOnLive: () => T): T;
  toJSONObject(): any;
  fromJSONObject(json: any): void;
  lockState(actionUnderLock: () => void): Promise<void>;
  eqCaptured(o: IStateSource): boolean;
}

export interface IReadonlyValue<T>
{
  readonly state: T | undefined;
  readonly error: any | undefined;
}

export interface IUpdatableValue<T>
{
  setNextState(nextState: T | undefined): void;
}

export interface IValue<T> extends IReadonlyValue<T>, IUpdatableValue<T>
{
}

export class StateSource implements IStateSource
{
  private readonly parent: IStateSource;
  private readonly stateName: string;
  private readonly eqStateMap: any;

  public constructor(parent: IStateSource, stateName: string)
  {
    this.parent = parent;
    this.stateName = stateName;
    this.eqStateMap = this.stateMap;
  }

  private get stateMap(): any
  {
    return this.parent.getState<any>(this.stateName);
  }

  public getState<T>(name: string): T | undefined
  {
    const map = this.stateMap;
    if (map)
    {
      return map[name];
    }

    return undefined;
  }

  public setNextState<T>(aCallback: (aStateMap: any) => void): void
  {
    this.parent.setNextState(((sm: any) =>
    {
      let map = sm[this.stateName];
      map = { ...map };
      sm[this.stateName] = map;
      aCallback(map);
    }));
  }

  public onLive<T>(actionOnLive: () => T): T
  {
    return this.parent.onLive<T>(actionOnLive);
  }

  public toJSONObject(): any
  {
    if (this.stateMap)
    {
      return { ...this.stateMap };
    }

    return undefined;
  }

  public fromJSONObject(json: any): void
  {
    this.parent.setNextState((stateMap: any) => stateMap[this.stateName] = json);
  }

  public lockState(actionUnderLock: () => void): Promise<void>
  {
    return this.parent.lockState(actionUnderLock);
  }

  public eqCaptured(o: IStateSource): boolean
  {
    if (o instanceof StateSource)
    {
      return this.eqStateMap === o.eqStateMap;
    }

    return false;
  }
}

export class ReadonlyStateSource extends StateSource
{
  public setNextState<T>(aCallback: (aStateMap: any) => void): Promise<void>
  {
    throw new Error('Can not modify a readonly statesource.');
  }
}

const defaultvalueDiffers = (oldVal: any | undefined, newVal: any | undefined) => oldVal !== newVal;

export class Value<T> implements IValue<T> {
  public readonly error: undefined;
  private readonly name: string;
  private readonly stateSource: IStateSource;
  private defaultValue?: T;
  public valueDiffers: (oldVal: T | undefined, newVal: T | undefined) => boolean;
  // stan na moment utworzenia wartości
  public readonly capturedState: T | undefined;

  public constructor(name: string, stateService: IStateSource, defaultValue?: T)
  {
    this.name = name;
    this.stateSource = stateService;
    this.defaultValue = defaultValue;
    this.valueDiffers = defaultvalueDiffers;
    this.capturedState = this.state;
  }

  public get state(): T | undefined
  {
    const v = this.stateSource.getState<T>(this.name);
    if (v !== undefined)
    {
      return v;
    }

    return this.defaultValue;
  }

  public setNextState(value: T | undefined): void
  {
    const storedState = this.stateSource.onLive(() => this.stateSource.getState<T>(this.name));
    if (this.valueDiffers(storedState, value))
    {
      this.stateSource.setNextState((stateMap: any) => stateMap[this.name] = value);
    }
  }

  public lockState(actionUnderLock: () => void): Promise<void>
  {
    return this.stateSource.lockState(actionUnderLock);
  }
}

interface IParamState<T, P>
{
  readonly param: P | undefined;
  readonly value: IReadonlyValue<T>;
}

export interface IParamValue<T, P> extends IReadonlyValue<T>
{
  readonly param: P | undefined;
  setNextParam(param?: P): Promise<T | undefined>;
}

const stringifyEq = (p1: any, p2: any) =>
{
  if (p1 === undefined)
  {
    return p2 === undefined;
  }
  else
  {
    return stringify(p1) === stringify(p2);
  }
};

export class AsyncParamValue<T, P> implements IParamValue<T, P> {

  private stateValue: Value<IParamState<T, P>>;
  public readonly param: P | undefined;
  private readonly stateService: IStateSource;
  private readonly valueName: string;
  public areParamsEqual: (p1: P | undefined, p2: P | undefined) => boolean;

  public constructor(
    stateService: IStateSource, valueName: string,
    private readonly providePromise: (param: P) => Promise<T | undefined>)
  {
    this.valueName = valueName;
    this.stateValue = new Value<IParamState<T, P>>(valueName, stateService);
    this.stateService = stateService;
    const pv = this.paramValue();
    this.param = pv.param;
    this.areParamsEqual = stringifyEq;
  }

  public get state(): T | undefined
  {
    return this.paramValue().value.state;
  }

  public get error(): any | undefined
  {
    return this.paramValue().value.error;
  }

  public setNextParam(param?: P): Promise<T | undefined>
  {
    return this.updateParam(param);
  }

  private setState(aValue: IParamState<T, P>): void
  {
    this.stateValue.setNextState(aValue);
  }

  private updateParam(aParam?: P): Promise<T | undefined>
  {
    const liveValue = this.stateService.onLive(() => this.stateService.getState<IParamState<T, P>>(this.valueName));
    const paramChanged: boolean = liveValue === undefined || !this.areParamsEqual(liveValue.param, aParam);
    if (paramChanged)
    {
      const newVal = { param: aParam, value: { state: undefined, error: undefined } };
      this.setState(newVal);
      if (aParam !== undefined)
      {
        return this.loadValue(aParam);
      }
    }
    return Promise.resolve(undefined);
  }

  private paramValue(): IParamState<T, P>
  {
    let v = this.stateValue.state;
    if (v === undefined)
    {
      v = { param: undefined, value: { state: undefined, error: undefined } };
    }
    return v;
  }

  private loadValue(aParam: P): Promise<T | undefined>
  {
    return this.providePromise(aParam).
      then((v: T | undefined) =>
      {
        return this.valueLoaded(aParam, v, aParam);
      }).
      catch((reason: any) =>
      {
        this.loadingError(aParam, reason);
        return Promise.reject(reason);
      });
  }

  protected valueLoaded(oldParam: P, newValue: T | undefined, newParam: P | undefined): Promise<T | undefined>
  {
    if (!this.paramIsObsolate(oldParam))
    {
      this.setState({ param: newParam || oldParam, value: { state: newValue, error: undefined } });
      return Promise.resolve(newValue);
    }
    else
    {
      return Promise.resolve(undefined);
    }
  }

  protected paramIsObsolate(oldParam: P | undefined)
  {
    const v = this.stateService.onLive(() => this.stateService.getState<IParamState<T, P>>(this.valueName));
    return v ? !this.areParamsEqual(v.param, oldParam) : false;
  }

  protected getLiveParam(): P | undefined
  {
    const v = this.stateService.onLive(() => this.stateService.getState<IParamState<T, P>>(this.valueName));
    return v ? v.param : undefined;
  }

  protected loadingError(aParam: P, reason: any)
  {
    this.setState({ param: aParam, value: { error: reason, state: undefined } });
  }

  public get isLoading()
  {
    return (this.param !== undefined) && (this.state === undefined) && (this.error === undefined);
  }
}

export function deepCopy(o: any): any
{
  if (o === undefined)
  {
    return undefined;
  }
  const sc = JSON.parse(JSON.stringify(o));
  return sc;
}
