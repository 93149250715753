export function errorToStr(err: any): string
{
  if (err instanceof Error)
  {
    return JSON.stringify(err, Object.getOwnPropertyNames(err));
  }
  else
  {
    return JSON.stringify(err);
  }
}

export const ErrorHandlers: { fileAndForget?: (err: any) => void } = {};

export function fileAndForget(err: any): void
{
  if (ErrorHandlers.fileAndForget)
  {
    ErrorHandlers.fileAndForget(err);
  }
}
