import { FieldValidator, DocField } from '../FormDoc';

/** Walidator implementujący taki intf może zażądać oznaczenia pola jako wymagane */
export interface WithShouldMarkFieldAsRequired
{
  shouldMarkFieldAsRequired(): boolean;
}

export function IsWithShouldMarkFieldAsRequired(v: any): v is WithShouldMarkFieldAsRequired
{
  return typeof v.shouldMarkFieldAsRequired === 'function';
}

export class FieldNotEmpty implements FieldValidator<any>, WithShouldMarkFieldAsRequired
{

  public validate(field: DocField<any>): string[]
  {
    return field.isControlEmpty()
      ? ['To pole trzeba wypełnić']
      : [];
  }

  public shouldMarkFieldAsRequired()
  {
    return true;
  }
}

export const fieldNotEmptyValidator = new FieldNotEmpty();

class FieldEmpty implements FieldValidator<any> {

  public validate(field: DocField<any>): string[]
  {
    return !field.isControlEmpty()
      ? ['To pole powinno być puste']
      : [];
  }
}

export const fieldEmptyValidator = new FieldEmpty();
