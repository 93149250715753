export const backofficeAuthToken = 'gegebege';

const envWdbServerAddress = process.env.GATSBY_WDB_SERVER_ADDRESS;
const envBrand = process.env.GATSBY_WDB_BRAND?.trim();

if (envBrand == null)
{
  throw new Error('Niezdefiniowane GATSBY_WDB_BRAND');
}

// czy branding ubezpieczeniadirect
export const envBrandWDB = envBrand === 'wdb';
// czy branding PZlow
export const envBrandPZlow = envBrand === 'pzlow';

export function serverAddress()
{
  if (envWdbServerAddress != null)
  {
    return envWdbServerAddress;
  }

  // serwer backoffice
  if (typeof window !== `undefined`)
  {
    if (window.location.hostname === 'localhost')
    {
      return 'http://localhost:9011';
    }
    else if (window.location.hostname === 'jb-wbd-nnw.firebaseapp.com')
    {
      return 'https://jb-wbd-nnw.appspot.com'
    }
    else if (window.location.hostname === 'uat-biu-wdb-nnw.firebaseapp.com')
    {
      return 'https://uat-dot-jb-wbd-nnw.appspot.com';
    }
    else if (window.location.hostname === 'uat-biu-wdb-nnw-pzlow.firebaseapp.com')
    {
      return 'https://uat-dot-jb-wbd-nnw.appspot.com';
    }


  }

  // domyślny - prod
  return 'https://ubezpieczeniadirect-pl.appspot.com';
}

export interface UploadSpec
{
  /* Url pod który należy wysłać plik */
  url: string;
  /* Przesłać jako uploadId załącznika */
  uploadId: string;
}

export function isUploadSpec(arg: any): arg is UploadSpec
{
  return (
    (arg !== undefined) &&
    (typeof (arg.uploadId) === 'string') &&
    (typeof (arg.url) === 'string')
  );
}

export const helpMail = 'nnwdirect@pib-broker.pl';
export const helpPhone1 = '+48510212550';
export const helpPhone2 = '+48571609488';
