import moment, { DurationInputArg2 } from 'moment';
import { LocalDateTime } from './LocalDateTime';

/**
 * Data oderwana od strefy czasowej
 * Wewnetrznie reprezentowana jest jako liczba ms od epoki data w strefie UTC
 * Wszelkie operacje na styku z Date należy wykonywać w strefie UTC!
 */
export class LocalDate
{
  private readonly value: number;

  public constructor(year: number, month: number, day: number);
  public constructor(value: number);
  constructor(valueOrYear: number, month?: number, day?: number)
  {
    this.value = (month !== undefined)
      ? moment.utc({ year: valueOrYear, month: month - 1, day }).valueOf()
      : valueOrYear;
  }

  /*
   * Wartosc wyrażona w ms od 01.01.1970 UTC
   */
  public valueOf(): number
  {
    return this.value;
  }

  public toString(): string
  {
    return this.toMomentUTC().format('YYYY-MM-DD');
  }

  public toDispString(): string
  {
    return this.toMomentUTC().format('DD.MM.YYYY');
  }

  public static today(): LocalDate
  {
    // Moment z lokalną datą-czasem
    const m = moment();
    // Przesunięty tak, aby wskazywać na tę samą datę-czas zegarową ale w strefie UTC
    const c = m
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .add(m.utcOffset(), 'minutes');
    return new LocalDate(c.valueOf());
  }

  public eq(other: LocalDate | undefined): boolean
  {
    return (other !== undefined) && (this.value === other.value);
  }

  public isAfter(other: LocalDate): boolean
  {
    return LocalDate.compare(this, other) > 0;
  }

  public static compare(d1: LocalDate, d2: LocalDate): number
  {
    return d1.value - d2.value;
  }

  public toJSON(): any
  {
    return this.value;
  }

  public static fromJSON(json: any): LocalDate | undefined
  {
    return (typeof json === 'number') ? new LocalDate(json) : undefined;
  }

  /**
   * Oddaje jako moment.
   * Ze względu na sposób reprezentacji, będzie to ta data 00:00 w strefie UTC
   */
  public toMomentUTC(): moment.Moment
  {
    return moment.utc(this.value);
  }

  /**
   * Konwersja z daty zapisanej jako moment w lok strefie na te sama wartosc w LocalDate
   */
  public static fromMoment(m: moment.Moment): LocalDate
  {
    const [year, month, date] = [m.year(), m.month(), m.date()];
    const v = moment.utc({ year, month, date });
    return new LocalDate(v.valueOf());
  }

  /**
   * LocalDateTime w godzinie 00:00
   */
  public toLocalDateTime(): LocalDateTime
  {
    return LocalDateTime.fromMoment(this.toMomentUTC());
  }

  /**
   * Oddaje date przesunieta o wskazana liczbe jednostek
   */
  public plus(amount: number, unit: DurationInputArg2): LocalDate
  {
    const m = this.toMomentUTC().add(amount, unit);
    return LocalDate.fromMoment(m);
  }

  public startOfMonth(): LocalDate
  {
    const m = this.toMomentUTC().startOf('month');
    return LocalDate.fromMoment(m);
  }

  public endOfMonth(): LocalDate
  {
    const m = this.toMomentUTC().endOf('month');
    return LocalDate.fromMoment(m);
  }

  public toUserInput(): string
  {
    return this.toString();
  }

  public static fromUserInput(s: string): LocalDate | undefined
  {
    if ((s ?? '') === '')
    {
      return undefined;
    }

    const date = new Date(Date.parse(s));
    return new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

}
