import { RpcBErrors } from '../type/RpcBErrors';
import { fileAndForget } from '../utl/ErrUtl';
import { BError, toBError } from '../model/BError';
import { IStateSource, StateSource, ReadonlyStateSource, Value, IReadonlyValue } from '../state/State';
import { AppError } from '../type/AppError';

export abstract class DocElement
{
  public abstract refreshValidation(errors: BError[]): void;
  public abstract hasErrors(): boolean;
  public abstract eqSaved(): boolean;
  public abstract setReadonly(readonly: boolean): void;
  public abstract clearErrors(): void;
  public abstract readonly uniqueId: string;
  public abstract eqCaptured(o: DocElement): boolean;
  public abstract fillFields(fieldMap: Map<string, DocField<any>>): void;
}

export interface IFormStateSource
{
  readonly dataSource: IStateSource;
  readonly savedSource: IStateSource;
  readonly errorsSource: IStateSource;
  readonly formSource: IStateSource;
  readonly uniqueId: string;
  readonly fieldName: string;
}

export interface FormDocViewData<D = any, V = any>
{
  doc: D;
  view?: V;
}

export class FieldContainer extends DocElement
{
  public readonly fields: DocElement[] = [];
  public readonly uniqueId: string;
  public readonly fieldName: string;

  public constructor(
    public readonly stateSource: IFormStateSource,
  )
  {
    super();
    this.fieldName = stateSource.fieldName;
    this.uniqueId = stateSource.uniqueId;
  }

  protected SubObject<T extends FieldContainer>(ctor: FieldContainerConstructor<T>, fieldName: string): T
  {
    const parent = this.stateSource;
    const fieldSS: IFormStateSource =
    {
      dataSource: new StateSource(parent.dataSource, fieldName),
      savedSource: new ReadonlyStateSource(parent.savedSource, fieldName),
      errorsSource: new StateSource(parent.errorsSource, fieldName),
      formSource: new StateSource(parent.formSource, fieldName),
      uniqueId: parent.uniqueId + '_' + fieldName,
      fieldName,
    };

    const obj = new ctor(fieldSS);
    this.add(obj);
    return obj;
  }

  public get dataSource(): IStateSource
  {
    return this.stateSource.dataSource;
  }

  public get savedSource(): IStateSource
  {
    return this.stateSource.savedSource;
  }

  public get errorsSource(): IStateSource
  {
    return this.stateSource.errorsSource;
  }

  public get formSource(): IStateSource
  {
    return this.stateSource.formSource;
  }

  protected addAllFieldsValidator(validator: FieldValidator<any>): void
  {
    this.fields
      .filter(f => f instanceof DocField)
      .map(f => f as DocField<any>)
      .forEach(f => f.addValidator(validator));
  }

  public add(field: DocElement)
  {
    this.fields.push(field);
  }

  public refreshValidation(errors: BError[]): void
  {
    this.dataSource.lockState(() =>
    {
      for (const field of this.fields)
      {
        field.refreshValidation(errors);
      }
    }).catch(fileAndForget);
  }

  public fillFields(fieldMap: Map<string, DocField<any>>): void
  {
    for (const field of this.fields)
    {
      field.fillFields(fieldMap);
    }
  }

  public getField(fieldName: string, deepSearch?: boolean): DocField<any> | undefined
  {
    for (const f of this.fields)
    {
      if (f instanceof DocField)
      {
        if (f.fieldName === fieldName)
        {
          return f;
        }
      }
      if (deepSearch && (f instanceof FieldContainer))
      {
        const fd = f.getField(fieldName, true);
        if (fd)
        {
          return fd;
        }
      }
    }
  }

  /**
   * Odczytuje stan czy są błędy
   */
  public hasErrors(): boolean
  {
    for (const field of this.fields)
    {
      if (field.hasErrors())
      {
        return true;
      }
    }
    return false;
  }

  public clearErrors(): void
  {
    for (const field of this.fields)
    {
      field.clearErrors();
    }
  }

  public eqSaved(): boolean
  {
    for (const field of this.fields)
    {
      if (!field.eqSaved())
      {
        return false;
      }
    }
    return true;
  }

  public eqCaptured(o: DocElement): boolean
  {
    if (!(o instanceof FieldContainer))
    {
      return false;
    }

    if (this.fields.length !== o.fields.length)
    {
      return false;
    }

    let i = 0;
    for (const field of this.fields)
    {
      const fo = o.fields[i++];
      if (!field.eqCaptured(fo))
      {
        return false;
      }
    }

    return true;
  }

  public setReadonly(readonly: boolean): void
  {
    for (const field of this.fields)
    {
      field.setReadonly(readonly);
    }
  }

  public onLiveLocked(action: () => void): Promise<void>
  {
    return this.dataSource.lockState(() =>
    {
      this.dataSource.onLive(() =>
      {
        action();
      }
      );
    }
    );
  }
}

export class ListElement extends FieldContainer
{
}

export class ArrayStateSource
{
  private capturedArray: any;

  public constructor(
    public readonly parent: IStateSource,
    private stateName: string)
  {
    this.capturedArray = this.stateArray;
  }

  public get stateArray(): any[] | undefined
  {
    return this.parent.getState<any>(this.stateName);
  }

  public getState(index: number): any
  {
    if (this.stateArray)
    {
      return this.stateArray[index];
    }

    return undefined;
  }

  public setNextState(aCallback: (stateArray: any[]) => void): void
  {
    this.parent.setNextState((sm: any) =>
    {
      let arr = sm[this.stateName] || [];
      arr = arr.slice();
      sm[this.stateName] = arr;
      aCallback(arr);
    });
  }

  public onLive<T>(actionOnLive: () => T): T
  {
    return this.parent.onLive<T>(actionOnLive);
  }

  public push(newElement?: (newIndex?: number) => any): number
  {
    let liveLength: number = -2;
    this.parent.setNextState(((sm: any) =>
    {
      let arr: any[] = sm[this.stateName] || [];
      arr = arr.slice();
      sm[this.stateName] = arr;
      const element = newElement ? newElement(arr.length) : {};
      arr.push(element);
      liveLength = arr.length;
    }));
    return liveLength;
  }

  public remove(index?: number): number
  {
    let liveLength: number = -3;
    this.parent.setNextState((sm: any) =>
    {
      let arr: any[] = sm[this.stateName] || [];
      arr = arr.slice();
      sm[this.stateName] = arr;
      const arrIdx = index === undefined ? arr.length - 1 : index;
      arr.splice(arrIdx, 1);
      liveLength = arr.length;
    });
    return liveLength;
  }

  public clear(): number
  {
    this.parent.setNextState((sm: any) =>
    {
      sm[this.stateName] = [];
    });
    return 0;
  }

  public lockState(actionUnderLock: () => void): Promise<void>
  {
    return this.parent.lockState(actionUnderLock);
  }

  public move(index: number, delta: number): void
  {
    this.parent.setNextState((sm: any) =>
    {
      let arr: any[] = sm[this.stateName] || [];
      arr = arr.slice();
      sm[this.stateName] = arr;

      const tmp = arr[index];
      arr[index] = arr[index + delta];
      arr[index + delta] = tmp;
    });
  }

  public eqCaptured(o: ArrayStateSource): boolean
  {
    return this.capturedArray === o.capturedArray;
  }
}

export class ArrayElementStateSource implements IStateSource
{
  private capturedMap: any;

  public constructor(
    private arraySource: ArrayStateSource,
    private index: number)
  {
    this.capturedMap = this.stateMap;
  }

  private get stateMap()
  {
    return this.arraySource.getState(this.index);
  }

  public getState<T>(name: string): T | undefined
  {
    const sm = this.stateMap;
    if (sm)
    {
      return sm[name];
    }

    return undefined;
  }

  public setNextState(aCallback: (stateMap: any) => void): void
  {
    this.arraySource.setNextState((stateArray: any[]) =>
    {
      let map = stateArray[this.index];
      map = { ...map };
      stateArray[this.index] = map;
      aCallback(map);
    });
  }

  public onLive<T>(actionOnLive: () => T): T
  {
    return this.arraySource.onLive<T>(actionOnLive);
  }

  public toJSONObject(): any
  {
    const sm = this.stateMap;
    if (sm)
    {
      return { ...sm };
    }

    return undefined;
  }

  public fromJSONObject(json: any): void
  {
    this.arraySource.setNextState((stateArray: any[]) => stateArray[this.index] = json);
  }

  public lockState(actionUnderLock: () => void): Promise<void>
  {
    return this.arraySource.parent.lockState(actionUnderLock);
  }

  public eqCaptured(o: IStateSource): boolean
  {
    if (o instanceof ArrayElementStateSource)
    {
      return this.capturedMap === o.capturedMap;
    }

    return false;
  }
}

export type FieldContainerConstructor<T extends FieldContainer> =
  new (stateSource: IFormStateSource) => T;

export class FieldContainerProvider<T>
{
  public constructor(private provideFunc: (stateSource: IFormStateSource) => T)
  {
  }

  public provide(stateSource: IFormStateSource): T
  {
    return this.provideFunc(stateSource);
  }
}

export class FormList<T extends FieldContainer> extends DocElement
{
  public readonly dataSource: ArrayStateSource;
  public readonly savedSource: ArrayStateSource;
  public readonly errorsSource: ArrayStateSource;
  public readonly formSource: ArrayStateSource;
  private readonly formData: IStateSource;
  private readonly viewOrder: ArrayStateSource;
  public readonly: boolean = false;
  private elementProvider: FieldContainerProvider<T>;
  public readonly uniqueId: string;

  public constructor(
    public readonly fieldName: string,
    private parent: FieldContainer,
    elementConstructor: FieldContainerConstructor<T> | FieldContainerProvider<T>)
  {
    super();
    this.uniqueId = parent.uniqueId + '_' + fieldName;
    this.dataSource = new ArrayStateSource(parent.dataSource, fieldName);
    this.savedSource = new ArrayStateSource(parent.savedSource, fieldName);
    this.errorsSource = new ArrayStateSource(parent.errorsSource, fieldName);
    this.formData = new StateSource(parent.formSource, fieldName);
    this.formSource = new ArrayStateSource(this.formData, fieldName);
    this.viewOrder = new ArrayStateSource(this.formData, 'viewOrder');
    this.parent.add(this);
    if (elementConstructor instanceof FieldContainerProvider)
    {
      this.elementProvider = elementConstructor;
    }
    else
    {
      const provide = (stateSource: IFormStateSource) => new elementConstructor(stateSource);
      this.elementProvider = new FieldContainerProvider(provide);
    }
  }

  public refreshValidation(errors: BError[]): void
  {
    this.createElements().forEach(e => e.refreshValidation(errors));
  }

  public fillFields(fieldMap: Map<string, DocField<any>>): void
  {
    this.forEach(e => e.fillFields(fieldMap));
  }

  public push(onAdded?: (element: T) => void): void
  {
    this.dataSource.parent.lockState(() =>
    {
      this.dataSource.push();
      this.errorsSource.push();
      this.formSource.push();
      if (this.viewOrder.stateArray)
      {
        this.viewOrder.push(newIndex => newIndex);
      }
      if (onAdded)
      {
        this.dataSource.parent.onLive(() => onAdded(this.get(this.length - 1)));
      }
    }).catch(fileAndForget);
  }

  public pop(): void
  {
    this.remove();
  }

  public remove(index?: number): void
  {
    this.dataSource.parent.lockState(() =>
    {
      let elementIndex = index;
      if (this.viewOrder.stateArray)
      {
        this.viewOrder.setNextState((stateArray: any[]) =>
        {
          const stateIdx = index === undefined ? stateArray.length - 1 : index;
          elementIndex = stateArray[stateIdx];
          stateArray.splice(stateIdx, 1);
        });
      }

      this.dataSource.remove(elementIndex);
      this.errorsSource.remove(elementIndex);
      this.formSource.remove(elementIndex);
    }).catch(fileAndForget);
  }

  public clear()
  {
    this.dataSource.parent.lockState(() =>
    {
      const clearIt = (stateArray: any[]) => stateArray.splice(0, stateArray.length);
      if (this.viewOrder.stateArray)
      {
        this.viewOrder.setNextState(clearIt);
      }
      this.dataSource.setNextState(clearIt);
      this.errorsSource.setNextState(clearIt);
      this.formSource.setNextState(clearIt);
    }).catch(fileAndForget);
  }

  public hasErrors(): boolean
  {
    for (const e of this.createElements())
    {
      if (e.hasErrors())
      {
        return true;
      }
    }

    return false;
  }

  public clearErrors(): void
  {
    for (const e of this.createElements())
    {
      e.clearErrors();
    }
  }

  public *[Symbol.iterator]()
  {
    for (let i = 0; i < this.length; i++)
    {
      yield this.get(i);
    }
  }

  public forEach(callbackfn: (value: T, index: number, array: T[]) => void, thisArg?: any): void
  {
    const elements = this.createElements();
    if (this.viewOrder.stateArray)
    {
      this.viewOrder.stateArray.forEach(v => callbackfn(elements[v], v, elements));
    }
    else
    {
      elements.forEach(callbackfn);
    }
  }

  public filter(callbackfn: (value: T, index: number, array: T[]) => boolean, thisArg?: any): T[]
  {
    const elements = this.createElements();
    if (this.viewOrder.stateArray)
    {
      return this.viewOrder.stateArray.filter(v => callbackfn(elements[v], v, elements));
    }
    else
    {
      return elements.filter(callbackfn);
    }
  }

  public map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[]
  {
    const elements = this.createElements();
    if (this.viewOrder.stateArray)
    {
      return this.viewOrder.stateArray.map(v => callbackfn(elements[v], v, elements));
    }
    else
    {
      return elements.map(callbackfn);
    }
  }

  public reduce<U>(
    callbackfn: (previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U, initialValue: U
  ): U
  {
    const elements = this.createElements();
    if (this.viewOrder.stateArray)
    {
      return this.viewOrder.stateArray.reduce(
        (pv: U, cv: number, ci: number, ca: number[]) =>
          callbackfn(pv, elements[cv], cv, elements), initialValue);
    }
    else
    {
      return elements.reduce(callbackfn, initialValue);
    }
  }

  public sort(compareFn: (a: T, b: T) => number): this
  {
    this.parent.dataSource.onLive(() =>
    {
      this.viewOrder.setNextState((stateArray: any[]) =>
      {
        stateArray.splice(0, stateArray.length);
        const liveElements = this.createElements();
        liveElements.forEach((v, i, a) => stateArray.push(i));
        stateArray.sort((a, b) => compareFn(liveElements[a], liveElements[b]));
      });
    });
    return this;
  }

  public move(absoluteIndex: number, delta: number): this
  {
    if (absoluteIndex + delta > this.length)
    {
      throw new Error(
        `absoluteIndex(${absoluteIndex})+delta(${delta}) nie może być większe niż this.elements.length(${this.length})`
      );
    }
    if (absoluteIndex + delta < 0)
    {
      throw new Error(`absoluteIndex(${absoluteIndex})+delta(${delta}) nie może być mniejsze od 0`);
    }

    this.dataSource.parent.lockState(() =>
    {
      if (this.viewOrder.stateArray)
      {
        this.viewOrder.setNextState((stateArray: any[]) =>
        {
          const tmpIndex = stateArray[absoluteIndex];
          stateArray[absoluteIndex] = stateArray[absoluteIndex + delta];
          stateArray[absoluteIndex + delta] = tmpIndex;
        });
      }

      this.dataSource.move(absoluteIndex, delta);
      this.errorsSource.move(absoluteIndex, delta);
      this.formSource.move(absoluteIndex, delta);
    }).catch(fileAndForget);
    return this;
  }

  public get(index: number): T
  {
    if (this.viewOrder.stateArray)
    {
      return this.createElement(this.viewOrder.stateArray[index]);
    }
    else
    {
      return this.createElement(index);
    }
  }

  public safeGet(index: number | undefined): T | undefined
  {
    if (index === undefined || !this.inRange(index))
    {
      return undefined;
    }

    return this.get(index);
  }

  public any(predicate: (element: T) => boolean): boolean
  {
    if (this.dataSource.stateArray !== undefined)
    {
      for (let index = 0; index < this.dataSource.stateArray.length; index++)
      {
        const element = this.createElement(index);
        if (predicate(element))
        {
          return true;
        }
      }
    }

    return false;
  }

  public inRange(index: number): boolean
  {
    return index >= 0 && index < this.length;
  }

  get length(): number
  {
    return this.dataSource.stateArray ? this.dataSource.stateArray.length : 0;
  }

  /**
   * Liczba elementów w tablicy stanu przed edycją (wczytanego z bazy lub po udanym zapisie)
   */
  get savedLength(): number
  {
    return this.savedSource.stateArray ? this.savedSource.stateArray.length : 0;
  }

  private createElements(): T[]
  {
    const elements: T[] = [];
    if (this.dataSource.stateArray)
    {
      for (let index = 0; index < this.dataSource.stateArray.length; index++)
      {
        const element = this.createElement(index);
        elements.push(element);
      }
    }
    return elements;
  }

  private createElement(index: number): T
  {
    const ds = new ArrayElementStateSource(this.dataSource, index);
    const ss = new ArrayElementStateSource(this.savedSource, index);
    const es = new ArrayElementStateSource(this.errorsSource, index);
    const fs = new ArrayElementStateSource(this.formSource, index);
    const elStateSource = {
      parent: this,
      dataSource: ds, errorsSource: es, formSource: fs, savedSource: ss, uniqueId: this.uniqueId + '_' + index,
      fieldName: index.toString()
    };
    const element = this.elementProvider.provide(elStateSource);
    element.setReadonly(this.readonly);
    return element;
  }

  public eqSaved(): boolean
  {
    const objArr: any[] = this.savedSource.stateArray || [];
    if (objArr.length === this.length)
    {
      for (const e of this.createElements())
      {
        if (!e.eqSaved())
        {
          return false;
        }
      }
      return true;
    }

    return false;
  }

  public eqCaptured(o: DocElement): boolean
  {
    if (!(o instanceof FormList))
    {
      return false;
    }

    return this.dataSource.eqCaptured(o.dataSource)
      && this.formData.eqCaptured(o.formData)
      && this.errorsSource.eqCaptured(o.errorsSource);
  }

  public setReadonly(readonly: boolean): void
  {
    this.readonly = readonly;
  }
}

export const FormStateSource = (stateSource: IStateSource, uniqueId: string) =>
{
  return {
    parent: undefined,
    dataSource: new StateSource(stateSource, 'data'),
    savedSource: new ReadonlyStateSource(stateSource, 'saved'),
    errorsSource: new StateSource(stateSource, 'errors'),
    formSource: new StateSource(stateSource, 'form'),
    uniqueId,
    fieldName: 'doc',
  };
};

export class FormDocData
{
  public readonly stateSource: IStateSource;
  public readonly formStateSource: IFormStateSource;
  public readonly docErrors: Value<BError[]>;
  public readonly viewSource: IStateSource;

  public constructor(public readonly parentStateSource: IStateSource, public readonly name: string)
  {
    this.stateSource = new StateSource(parentStateSource, name);
    this.formStateSource = FormStateSource(this.stateSource, name);
    this.docErrors = new Value('docErrors', this.stateSource);
    this.viewSource = new StateSource(this.stateSource, 'viewData');
  }
}

export type FormDocConstructor<T extends FormDoc> = new (docData: FormDocData) => T;

export interface DocTransferData
{
  editableData?: any;
  originalData?: any;
}

export class FormDoc extends FieldContainer
{
  public readonly uniqueId: string;
  public constructor(protected readonly docData: FormDocData)
  {
    super(docData.formStateSource);
    this.uniqueId = docData.name;
  }

  public static fromStateSource(stateSource: IStateSource, dataName: string)
  {
    return new FormDoc(new FormDocData(stateSource, dataName));
  }

  public toJSON(): DocTransferData
  {
    let json = this.dataSource.toJSONObject();
    json = this.deepCopy(json);
    return { editableData: json, originalData: this.savedSource.toJSONObject() };
  }

  public fromJSON(data: FormDocViewData<DocTransferData>, docErrors: BError[]): void
  {
    if (data === undefined)
    {
      data = { doc: { editableData: undefined, originalData: undefined } };
    }

    this.dataSource.lockState(() =>
    {
      this.dataSource.fromJSONObject(this.deepCopy(data.doc.editableData));
      this.savedSource.fromJSONObject(this.deepCopy(data.doc.originalData));
      this.viewSource.fromJSONObject(data.view);
      this.errorsFromJSON(docErrors);
    }).catch(fileAndForget);
  }

  private deepCopy(o: any): any
  {
    if (o === undefined)
    {
      return undefined;
    }
    const sc = JSON.parse(JSON.stringify(o));
    return sc;
  }

  public errorsFromJSON(docErrors: BError[] | undefined): void
  {
    this.docData.docErrors.setNextState(docErrors);
  }

  // Dodaje e.message do błędów dokumentu
  public addErrorFromError(e: any)
  {
    const es = this.docErrors();
    this.docData.docErrors.setNextState(es.concat(toBError(e)));
  }

  // Dodaje komunikat błędu do błędów dokumentu
  public addSimpleError(message: string, path: string | null = null)
  {
    const es = Array.from(this.docErrors());
    es.push({ path, message });
    this.docData.docErrors.setNextState(es);
  }

  public fromDoc(other: FormDoc): void
  {
    this.dataSource.fromJSONObject(other.dataSource.toJSONObject());
    this.stateSource.formSource.fromJSONObject(other.stateSource.formSource.toJSONObject());
  }

  protected get viewSource(): IStateSource
  {
    return this.docData.viewSource;
  }

  public hasErrors(): boolean
  {
    if (this.docErrors().length > 0)
    {
      return true;
    }

    return super.hasErrors();
  }

  public hasFieldErrors(): boolean
  {
    return super.hasErrors();
  }

  public docErrors(): BError[]
  {
    const de = this.docData.docErrors.state;
    return de ? de : [];
  }

  public hasData(): boolean
  {
    return this.dataSource.toJSONObject() !== undefined;
  }

  // Zbiera błedy dokumentu i nadziewa na errors
  // Oddaje false jeśli są błedy
  public refreshValidationSuccess(errors: BError[]): boolean
  {
    this.refreshValidation(errors);
    return errors.length === 0;
  }

  public refreshValidation(errors: BError[]): void
  {
    super.refreshValidation(errors);
    this.validate(errors);
    this.docData.docErrors.setNextState(errors);
  }

  /**
   * Odświeża i oddaje czy brak błędów
   * @param retErrors Jeśli podane to nadzieje błędy
   */
  public isValid(retErrors?: BError[]): boolean
  {
    const errors: BError[] = [];
    this.refreshValidation(errors);
    if (retErrors !== undefined)
    {
      retErrors.push(...errors);
    }
    return errors.length === 0;
  }

  /** Odnawia sprawdzenia i rzuca jeśli są błędy */
  public throwIfInvalid(): void
  {
    const errors: BError[] = [];
    this.refreshValidation(errors);
    if (errors.length > 0)
    {
      throw new RpcBErrors(errors);
    }
  }

  /**
   * Sprawdzenia na poziomie dokumentu
   * @param errors nadziać błędy
   */
  protected validate(errors: BError[]): void
  // tslint:disable-next-line:no-empty
  { }

  public hasChanged(): boolean
  {
    return !this.eqSaved();
  }

  /**
   * Czysci dane
   */
  public reset()
  {
    this.fromJSON({ doc: {} }, []);
  }

  /**
   * Odrzuca zmiany dokumentu, przywraca stan do stanu odczytanego z bazy
   * Czyści błędy dokumentu i pól
   */
  public discardChanges()
  {
    const originalData = this.savedSource.toJSONObject();
    const editableData = this.deepCopy(originalData);
    delete editableData.__signature;

    this.fromJSON({ doc: { editableData, originalData } }, []);
    this.clearErrors();
  }
}

// tslint:disable-next-line:max-classes-per-file
export abstract class DocField<T> extends DocElement
{
  public readonly validators: Array<FieldValidator<T>> = [];
  private readonly value: Value<T>;
  private readonly fsavedValue: Value<T>;
  private readonly errors: Value<BError[]>;
  public readonly: boolean;
  public disableValidation: boolean;
  public readonly formStateSource: IStateSource;
  private controlValue: IReadonlyValue<T> | undefined;
  public isHidden: boolean = false;
  public readonly uniqueId: string;

  public constructor(
    public readonly fieldName: string, public fieldLabel: string,
    private readonly fieldContainer: FieldContainer)
  {
    super();
    this.uniqueId = fieldContainer.uniqueId + '_' + fieldName;
    this.fieldContainer = fieldContainer;
    this.fieldContainer.add(this);
    this.value = new Value(fieldName, fieldContainer.dataSource);
    this.fsavedValue = new Value(fieldName, fieldContainer.savedSource);
    this.errors = new Value(fieldName, fieldContainer.errorsSource);
    this.errors.valueDiffers = (oldVal: BError[] | undefined, newVal: BError[] | undefined) =>
    {
      if (!oldVal)
      {
        return newVal !== undefined && newVal.length > 0;
      }
      return JSON.stringify(oldVal) !== JSON.stringify(newVal);
    };
    this.formStateSource = new StateSource(fieldContainer.formSource, fieldName);
    this.readonly = false;
    this.disableValidation = false;
  }

  public setHidden(val: boolean): this
  {
    this.isHidden = val;
    return this;
  }

  public getValue(): T | undefined
  {
    return this.jsonToValue(this.value.state);
  }

  public getDefinedValue(): T
  {
    const val = this.getValue();
    if (val === undefined)
    {
      throw new AppError('Brak wartości w polu: ' + this.fieldName);
    }

    return val;
  }

  public setValue(data: T | undefined): void
  {
    this.controlValue = { state: data, error: undefined };
    this.value.setNextState(this.valueToJson(data));
  }

  public getControlValue(): T | undefined
  {
    return this.controlValue === undefined ? this.getValue() : this.controlValue.state;
  }

  public get savedValue(): T | undefined
  {
    return this.jsonToValue(this.fsavedValue.state);
  }

  protected valueToJson(data: T | undefined): any
  {
    return data;
  }

  protected jsonToValue(json: any): T | undefined
  {
    return json;
  }

  public getErrors(): BError[]
  {
    return this.errors.state || [];
  }

  public setErrors(errors: BError[]): void
  {
    this.errors.setNextState(errors);
  }

  public addError(message: string): BError
  {
    const error = { path: this.uniqueId, message };
    this.errors.setNextState([...this.getErrors(), error]);
    return error;
  }

  public clearErrors(): void
  {
    this.setErrors([]);
  }

  public hasErrors(): boolean
  {
    return this.getErrors().length > 0;
  }

  public addValidator(validator: FieldValidator<T> | Array<FieldValidator<T>>)
  {
    const validators = (validator instanceof Array) ? validator : [validator];
    for (const v of validators)
    {
      this.validators.push(v);
    }
  }

  public addValidators(...validators: Array<FieldValidator<T>>)
  {
    for (const v of validators)
    {
      this.validators.push(v);
    }
  }

  public removeValidators(): void
  {
    this.validators.splice(0, this.validators.length);
  }

  protected validate(): BError[]
  {
    if (this.disableValidation)
    {
      return [];
    }
    return this.validators
      .map(v =>
        v.validate(this).map(e =>
        {
          return { path: this.uniqueId, message: e };
        }))
      .reduce((prev, curr) => prev.concat(curr), []);
  }

  public refreshValidation(errors?: BError[]): void
  {
    const ferrors = this.validate();
    this.setErrors(ferrors);
    if (errors)
    {
      errors.push(...ferrors);
    }
  }

  public fillFields(fieldMap: Map<string, DocField<any>>): void
  {
    fieldMap.set(this.uniqueId, this);
  }

  /**
   * Ustawienie wartości domyślnej
   * Ponieważ ustawia wartość, trzeba wołać w evencie FormDoc.fillDefaultData
   */
  public setDefaultValue(defVal?: T): void
  {
    if (defVal !== undefined)
    {
      const val = this.getValue();
      if (val === undefined)
      {
        this.setValue(defVal);
      }
    }
  }

  public eqSaved(): boolean
  {
    const saved = this.savedValue;
    const current = this.getValue();
    if (!saved && !current)
    {
      return true;
    }

    return saved === current;
  }

  public eqCaptured(o: DocElement): boolean
  {
    if (!(o instanceof DocField))
    {
      return false;
    }

    const saved = o.value.capturedState;
    const current = this.value.capturedState;
    const formEq = this.formStateSource.eqCaptured(o.formStateSource)
      && this.fieldContainer.errorsSource.eqCaptured(o.fieldContainer.errorsSource);
    if (saved === undefined && current === undefined)
    {
      return formEq;
    }

    return formEq && (saved === current);
  }

  public isControlEmpty(): boolean
  {
    const v = this.getControlValue();
    return v === undefined || v == null;
  }

  public setReadonly(readonly: boolean): void
  {
    this.readonly = readonly;
  }

  public withValidator(validator: FieldValidator<T> | Array<FieldValidator<T>>): this
  {
    this.addValidator(validator);
    return this;
  }

}

export interface FieldValidator<T>
{
  /**
   * Sprawdzić wartość pola field i oddać listę błędów
   * Wartość pola należy odczytywać z getControlValue
   * @param field
   */
  validate(field: DocField<T>): string[];
}
