export interface ConverterElement<T>
{
  readonly key: any;
  readonly value: T;
  readonly withdrawn: boolean;
}

export interface Converter<T>
{
  /** Konwersja z klucza na opis */
  convert(key: any): T;
  count(): number;
  elementAt(index: number): ConverterElement<T>;
  toJSON(): any;
  forEach(action: (element: ConverterElement<T>, idx?: number) => void): void;
  map<U>(callbackfn: (value: ConverterElement<T>, index: number) => U, thisArg?: any): U[];
  asArray(): ConverterElement<T>[];
}

export class MapConverter<T> implements Converter<T>
{
  private elementsMap: Map<any, any> = new Map();

  public constructor(private elements: ConverterElement<T>[])
  {
    if (elements !== undefined)
    {
      elements.forEach(e => this.elementsMap.set(e.key, e.value));
    }
    else
    {
      this.elements = [];
    }
  }

  public static fromJSON<T>(json: any): MapConverter<T>
  {
    return new MapConverter(json);
  }

  public forEach(action: (element: ConverterElement<T>, idx?: number) => void)
  {
    this.elements.forEach(action);
  }

  public map<U>(callbackfn: (value: ConverterElement<T>, index: number) => U, thisArg?: any): U[]
  {
    return this.elements.map(callbackfn, thisArg);
  }

  public convert(key: any): any
  {
    return this.elementsMap.get(key);
  }

  public count(): number
  {
    return this.elements.length;
  }

  public elementAt(index: number): ConverterElement<T>
  {
    return this.elements[index];
  }

  public toJSON(): any
  {
    return this.elements.slice();
  }

  public asArray()
  {
    return this.elements.slice();
  }
}
