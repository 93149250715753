import * as HttpStatus from 'http-status-codes';
import { RpcError } from '../type/RpcError';
import { BError } from '../model/BError';

export class RpcBErrors extends RpcError
{
  public constructor(berrors: BError[] | BError)
  {
    if (!Array.isArray(berrors))
    {
      berrors = [berrors];
    }
    super(HttpStatus.BAD_REQUEST, { berrors });
  }
}
