
import { StringUtils } from '../../utl/StringUtils';
import { FieldValidator, DocField } from '../FormDoc';

export class MinLength implements FieldValidator<string> {

  public constructor(public readonly minLength: number) { }

  public validate(field: DocField<string>): string[]
  {
    if ((field.getControlValue() || '').length < this.minLength)
    {
      return [`Minimalna długość to ${this.minLength}.`];
    }
    return [];
  }

}

export class MinLengthNE implements FieldValidator<string> {

  public constructor(public readonly minLength: number) { }

  public validate(field: DocField<string>): string[]
  {
    const controlValue = field.getControlValue() || '';
    if (controlValue !== '')
    {
      if (controlValue.length < this.minLength)
      {
        return [`Minimalna długość to ${this.minLength}.`];
      }
    }
    return [];
  }

}

export class MaxLength implements FieldValidator<string> {

  public constructor(public readonly maxLength: number) { }

  public validate(field: DocField<string>): string[]
  {
    if ((field.getControlValue() || '').length > this.maxLength)
    {
      return [`Maksymalna długość to ${this.maxLength}.`];
    }
    return [];
  }

}

export class RegExpValidation implements FieldValidator<string> {

  public constructor(
    public readonly expr: RegExp,
    public readonly msgFactory: () => string,
    /** Czy uruchamia walidację regExpr tylko na polu z wartością */
    public readonly onlyIfDefined: boolean = false) { }

  public validate(field: DocField<string>): string[]
  {
    const controlValue = field.getControlValue();
    if ((controlValue !== undefined) || !this.onlyIfDefined)
    {
      const controlValueStr = controlValue || '';
      const result = this.expr.exec(controlValueStr);
      const match = result !== null && result[0] === controlValueStr;
      return match ? [] : [this.msgFactory()];
    }
    else
    {
      return [];
    }
  }

}

/**
 * Uruchamia walidację tylko na niepustym polu
 */
export class RegExpNEValidation implements FieldValidator<string> {

  public constructor(public readonly expr: RegExp, public readonly msgFactory: () => string) { }

  public validate(field: DocField<string>): string[]
  {
    const controlValue = field.getControlValue() || '';
    if (controlValue !== '')
    {
      const result = this.expr.exec(controlValue);
      const match = result !== null && result[0] === controlValue;
      return match ? [] : [this.msgFactory()];
    }
    else
    {
      return [];
    }
  }

}

export class PLIBANValidator implements FieldValidator<string> {

  public validate(field: DocField<string>): string[]
  {
    const kontoMsg = field.isControlEmpty() ? '' : StringUtils.kontoOK(field.getControlValue() || '');
    return (kontoMsg === '') ? [] : [kontoMsg];
  }

}

export const plIBANValidator = new PLIBANValidator();

export class PasswordValidator implements FieldValidator<string> {

  public validate(field: DocField<string>): string[]
  {
    const controlValue = field.getControlValue() || '';
    const expr = /^(?=.*[A-Z])(?=.*[!@#$%^&*~`,.<>\/?'";:\]\[{}=+\-_)(\\|])(?=.*[0-9])(?=.*[a-z]).{5,}$/;
    const result = expr.exec(controlValue);
    const match = result !== null && result[0] === controlValue;
    return match ? [] : [
      'Słabe hasło. Wymagane: minimum 5 znaków, mała litera, duża litera,' +
      ' cyfra i znak specjalny !@#$%^&*~`,.<>/?\'";:][{}=+-_)(\\|'];
  }
}

/**
 * Walidator na zgodność wartości pól
 */
export class FieldEqualsOtherValidator implements FieldValidator<string> {

  constructor(public readonly anotherField: DocField<string>) { }

  public validate(field: DocField<string>): string[]
  {
    // Jeśli jest podana wartość, to ma być tożsama wynikowi sprawdzenia anotherField !== undefined
    if (field.getValue() !== this.anotherField.getValue())
    {
      return ['Wartość w polu "' + field.fieldLabel +
        '" powinna być równa wartości w polu "' + this.anotherField.fieldLabel + '"'];
    }
    return [];
  }

}
