import { parse } from 'query-string';

export const copyToClipboard = (str: string) =>
{
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const IsDevVersion = process.env.APP_VERSION === 'development';

/**
 * Konwersja z parametrów zapytania (w url po ?) na obiekt.
 * Tylko parametry typu string
 */
export function parseSearch(q: string): { [key: string]: string | undefined }
{
  const qp = parse(q);
  const result: any = {};
  const f = qp.hasOwnProperty ? qp.hasOwnProperty : () => true;
  for (const key in qp)
  {
    if (f(key))
    {
      const v = qp[key];
      if (typeof v === 'string')
      {
        result[key] = v;
      }
    }
  }
  return result;
}


export function isSamePath(url: string)
{
  if (typeof window !== `undefined`)
  {
    const urlObj = new URL(window.location.origin + url);
    const currentUrl = new URL(window.location.href);

    return urlObj.pathname === currentUrl.pathname;
  }

  return false;
}
