import React from 'react';

export interface MountedState
{
  isMounted: boolean;
}

export function useMounted(): MountedState
{
  const [state] = React.useState<MountedState>(
    {
      isMounted: false,
    });

  React.useEffect(() =>
  {
    if (!state.isMounted)
    {
      state.isMounted = true;
    }

    return () =>
    {
      state.isMounted = false;
    };
  }, []);

  return state;
}
