import { fileAndForget } from '../utl/ErrUtl';
import { IStateSource } from './State';
import { ManualPromise } from '../utl/ManualPromise';

export abstract class BaseStateSource implements IStateSource
{
  private stateLock: number = 0;
  private liveLock: number = 0;

  public constructor(
    protected liveMap: any
  ) { }

  private getStateMap(): any
  {
    return this.liveLock === 0
      ? this.stateMap()
      : this.liveMap;
  }

  public getState<T>(name: string): T | undefined
  {
    const stateMap = this.getStateMap();
    if (stateMap)
    {
      return stateMap[name];
    }
    return undefined;
  }

  public setNextState<T>(aCallback: (aStateMap: any) => void): void
  {
    aCallback(this.liveMap);
    this.syncState().catch(fileAndForget);
  }

  public onLive<T>(actionOnLive: () => T): T
  {
    this.liveLock++;
    try
    {
      return actionOnLive();
    }
    finally
    {
      this.liveLock--;
    }
  }

  public toJSONObject(): any
  {
    const stateMap = this.getStateMap();
    if (stateMap)
    {
      return { ...stateMap };
    }

    return undefined;
  }

  public fromJSONObject(json: any): void
  {
    this.liveMap = json;
    this.updateState().catch(fileAndForget);
  }

  public lockState(actionUnderLock: () => void): Promise<void>
  {
    this.stateLock++;
    try
    {
      actionUnderLock();
    }
    finally
    {
      this.stateLock--;
    }
    return this.syncState();
  }

  private lockPromise: ManualPromise<void> | undefined;

  private async syncState(): Promise<void>
  {
    // tslint:disable-next-line:no-console
    // console.log(this.getLiveMap());
    if (this.stateLock === 0)
    {
      await this.updateState();
      if (this.lockPromise)
      {
        this.lockPromise.resolve();
        this.lockPromise = undefined;
      }
    }
    else
    {
      if (this.lockPromise === undefined)
      {
        this.lockPromise = new ManualPromise();
      }

      return this.lockPromise.promise;
    }
  }

  protected abstract updateState(): Promise<void>;
  protected abstract stateMap(): any;

  public abstract eqCaptured(o: IStateSource): boolean;

}

export class ObjectStateSource extends BaseStateSource
{
  private state: any;

  public constructor(liveMap: any = {})
  {
    super(liveMap);
    this.updateState().catch(fileAndForget);
  }

  protected stateMap(): any
  {
    return this.state;
  }

  public updateState(): Promise<void>
  {
    this.state = { ...this.liveMap };
    return Promise.resolve();
  }

  public eqCaptured(o: IStateSource): boolean
  {
    if (o instanceof ObjectStateSource)
    {
      return this.state === o.state;
    }

    return false;
  }

}
