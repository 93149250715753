import { errorToStr } from '../utl/ErrUtl';

export interface BError
{
  path: string | null;
  message: string;
}

export function toBError(error: any): BError[]
{
  if (error === undefined)
  {
    return [];
  }

  if (error.json && error.json.berrors && Array.isArray(error.json.berrors))
  {
    return error.json.berrors;
  }

  if (error.berrors && Array.isArray(error.berrors))
  {
    return error.berrors;
  }

  if (typeof error.message === 'string')
  {
    return [{ path: null, message: error.message }];
  }

  if (typeof error === 'string')
  {
    return [{ path: null, message: error }];
  }

  return [{ path: null, message: errorToStr(error) }];
}

export function toBErrorOrMessage(error: any, message: string): BError[]
{
  if (error === undefined)
  {
    return [];
  }

  if (error.berrors && Array.isArray(error.berrors))
  {
    return error.berrors;
  }

  return [{ path: null, message }];
}

export interface ErrorResp<T>
{
  payload: T | undefined;
  error?: any;
}
