import React, { PropsWithChildren, useEffect } from 'react';
import { GlobalStateContext } from '../components/GlobalStateContext';
import { useStateSource } from '../common/hooks/StateSourceHook';
import { AccidentPolicy, RegistrationSourceKey } from '../model/policy/AccidentPolicy';
import { parseSearch } from '../common/utl/EnvUtl';

export function wrapRootElement(props: { element: any })
{
  return <GlobalStateProvider>{props.element}</GlobalStateProvider>
}

function GlobalStateProvider(props: PropsWithChildren<{}>)
{
  const stateSource = useStateSource();
  const policyDoc = new AccidentPolicy(stateSource);

  useEffect(() =>
  {
    policyDoc.formSource.onLive(() =>
    {
      if (policyDoc.source.getValue() === undefined)
      {
        policyDoc.source.setValue(RegistrationSourceKey.ubezpieczeniadirect);

        const q = parseSearch(window.location.search);
        const { ac } = q;
        if (ac)
        {
          policyDoc.formData.affiliateCode.setValue(ac);
        }
      }

      if (policyDoc.formData.insuredPersons.length === 0)
      {
        policyDoc.formData.insuredPersons.push();
      }
    });
  });

  return <GlobalStateContext.Provider value={{ policyDoc }}>
    {props.children}
  </GlobalStateContext.Provider>
}
