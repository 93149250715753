import { isNullOrUndefined } from 'util';
import { DocField, FieldContainer } from '../FormDoc';

let GlobalDocTextFieldUppercase: boolean = false;

export class DocTextField extends DocField<string> {

  public upperCase: boolean = GlobalDocTextFieldUppercase;
  public maxLength?: number;

  public constructor(
    fieldName: string,
    fieldLabel: string,
    datasource: FieldContainer
  )
  {
    super(fieldName, fieldLabel, datasource);
  }

  public isControlEmpty(): boolean
  {
    const v = this.getControlValue();
    return !isNullOrUndefined(v) ? v.toString().trim() === '' : true;
  }

  public static set GlobalDocTextFieldUppercase(val: boolean)
  {
    GlobalDocTextFieldUppercase = val;
  }
}
