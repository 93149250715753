import { FieldValidator, DocField } from '../FormDoc';

export class Immutable implements FieldValidator<any> {

  public validate(field: DocField<any>): string[]
  {
    return (field.savedValue === undefined) || (field.savedValue === field.getValue()) || field.eqSaved()
      ? []
      : ['Nie można zmieniać wartości tego pola.'];
  }
}

export const fieldImmutable = new Immutable();
