import { StorableDoc } from '../../common/doc/StorableDoc';
import { DocTextField } from '../../common/doc/fields/DocTextField';
import { DocEnumField } from '../../common/doc/fields/DocEnumField';
import { DocBooleanField } from '../../common/doc/fields/DocBooleanField';
import { DocLocalDateField } from '../../common/doc/fields/DocLocalDateField';
import { ListElement, IFormStateSource, FormList, FieldContainerProvider } from '../../common/doc/FormDoc';
import { IStateSource } from '../../common/state/State';
import { fieldNotEmptyValidator } from '../../common/doc/validators/FieldNotEmpty';
import { ValueRequired } from '../../common/doc/validators/BooleanFieldValidator';
import { MaxLength, RegExpValidation, MinLength } from '../../common/doc/validators/TextValidator';
import { PESELValidator } from '../../common/doc/validators/PLIdentValidator';
import { EmailValidator } from '../../common/doc/validators/EmailValidator';
import { DocLocalDateTimeField } from '../../common/doc/fields/DocLocalDateTimeField';
import { fieldImmutable } from '../../common/doc/validators/Immutable';
import { DocNumberField } from '../../common/doc/fields/DocNumberField';
import { floatToKwota0 } from '../../common/type/Kwota0';
import { ConditionalValidator } from '../../common/doc/validators/ConditionalValidator';
import { FuncValidator } from '../../common/doc/validators/FuncValidator';
import { StringUtils } from '../../common/utl/StringUtils';
import { LocalDate } from '../../common/type/LocalDate';
import { prettyPrintPLPhoneNumber } from '../../common/utl/PhoneNumberUtils';
import { envBrandPZlow, envBrandWDB, helpPhone1 } from '../Const';
import { FormMap } from '../../common/doc/FormMap';
import
{
  Consent, CsWDBRules24Code, CsWDBPrivacy24Code, CsWDBCommercial24Code, CsWDBTelemarketing24Code, CsIROWUSend24Code, CsIROWUAccept2Code,
  CsIRShareCode, CsIRNFZCode, CsIRMarketingCode, CsIRMarketing2Code,
  CsWDBRules24PZlowCode, CsWDBPrivacy24PZlowCode, CsIROWUSend24PZlowCode,
  CsIRDokMedCode
} from '../consent/Consent';
import { BError } from '../../common/model/BError';
import { PLPhoneNumberValidator } from '../../common/doc/validators/PhoneNumberValidator';
import { PLPostCodeValidator } from '../../common/doc/validators/PostCodeValidator';

export enum PolicyStatus
{
  New = 1,
  DocsSent = 2,
  Accepted = 3,
  Paid = 4,
  PaymentFailed = 5,
}

export enum RiskVariant
{
  Variant1 = '1',
  Variant2 = '2',
  Variant3 = '3'
}

const Variants = {
  [RiskVariant.Variant1]:
  {
    name: 'Wariant I',
    premium: floatToKwota0(60),
    premiumExtreme: floatToKwota0(70),
    insuredSum: floatToKwota0(22000),
  },
  [RiskVariant.Variant2]:
  {
    name: 'Wariant II',
    premium: floatToKwota0(90),
    premiumExtreme: floatToKwota0(100),
    insuredSum: floatToKwota0(34000),
  },
  [RiskVariant.Variant3]:
  {
    name: 'Wariant III',
    premium: floatToKwota0(140),
    premiumExtreme: floatToKwota0(150),
    insuredSum: floatToKwota0(58000),
  }
};

export enum SMSStatus
{
  Unsent = '1',
  Sent = '2',
  RenewalFound = '3'
}

export enum RegistrationSourceKey
{
  ubezpieczeniadirect = '1',
  pzlow = '2',
}

export const AccidentPolicyCodes = [
  ...(envBrandWDB ? [CsWDBRules24Code] : []),
  ...(envBrandPZlow ? [CsWDBRules24PZlowCode] : []),
  ...(envBrandWDB ? [CsWDBPrivacy24Code] : []),
  ...(envBrandPZlow ? [CsWDBPrivacy24PZlowCode] : []),
  CsWDBCommercial24Code,
  CsWDBTelemarketing24Code,
  ...(envBrandWDB ? [CsIROWUSend24Code] : []),
  ...(envBrandPZlow ? [CsIROWUSend24PZlowCode] : []),
  CsIRShareCode,
  CsIRNFZCode,
  CsIRDokMedCode,
];

if (!LocalDate.today().isAfter(new LocalDate(2020, 10, 31)))
{
  AccidentPolicyCodes.push(
    CsIRMarketingCode,
    CsIRMarketing2Code
  );
}

export const AccidentPolicyReqCodes = [
  ...(envBrandWDB ? [
    CsWDBRules24Code,
    CsWDBPrivacy24Code,
    CsIROWUSend24Code
  ] : []),
  ...(envBrandPZlow ? [
    CsWDBRules24PZlowCode,
    CsWDBPrivacy24PZlowCode,
    CsIROWUSend24PZlowCode
  ] : []),
  CsIRShareCode,
  CsIRNFZCode,
  CsIRDokMedCode
];

export const AccidentPolicyBuyCodes = [
  CsIROWUAccept2Code
];

export const AccidentPolicyBuyReqCodes = [
  CsIROWUAccept2Code
];

/** Lista kodów wymaganych zgód */
export function getRequiredConsents(
  _sourceKey: RegistrationSourceKey,
  owuSendingRequested: boolean
): string[]
{
  const result: string[] = [];
  result.push(...AccidentPolicyReqCodes);
  if (owuSendingRequested)
  {
    result.push(...AccidentPolicyBuyReqCodes);
  }
  return result;
}

export class AccidentPolicy extends StorableDoc
{
  public readonly certBlobId = new DocTextField('certBlobId', 'Certyfikat', this);
  public readonly insurerName = new DocTextField('insurerName', 'Nazwa towarzystwa *', this);
  public readonly source = new DocEnumField<RegistrationSourceKey>('source', 'Źródło', this);

  // wysyłka OWU po kroku pierwszym
  public readonly owuSent = new DocBooleanField('owuSent', 'Czy wysłano OWU', this);
  public readonly owuSendingRequested = new DocBooleanField('owuSendingRequested', 'Czy zażądano wysyłki OWU', this);
  public readonly owuSendingRequestDate =
    new DocLocalDateTimeField('owuSendingRequestDate', 'Data żądania wysyłki OWU', this);
  public readonly owuSentDate =
    new DocLocalDateTimeField('owuSentDate', 'Data wysyłki OWU', this);
  public readonly registrationDate =
    new DocLocalDateTimeField('registrationDate', 'Data rejestracji polisy', this);
  public readonly status = new DocEnumField<PolicyStatus>('status', 'Status', this);
  public readonly postBuyMailSent = new DocBooleanField('postBuyMailSent', 'Czy wysłano certyfikat', this);
  public readonly postBuyMailSentDate =
    new DocLocalDateTimeField('postBuyMailSentDate', 'Data wysyłki certyfikatu', this);

  public readonly formData = this.SubObject(FormData, 'formData');
  public readonly insurerReport = this.SubObject(ReportRef, 'insurerReport');
  public readonly payment = this.SubObject(PaymentData, 'payment');

  public readonly sms14Status = new DocEnumField<SMSStatus>('sms14Status', 'SMS 14 dni przed końcem', this);
  public readonly sms14Date = new DocLocalDateTimeField('sms14Date', 'Data wysyłania SMS', this);
  public readonly mail7Status = new DocEnumField<SMSStatus>('mail7Status', 'email 7 dni przed końcem', this);
  public readonly mail7Date = new DocLocalDateTimeField('mail7Date', 'Data wysyłania email', this);
  public readonly sms1Status = new DocEnumField<SMSStatus>('sms1Status', 'SMS 1 dni przed końcem', this);
  public readonly sms1Date = new DocLocalDateTimeField('sms1Date', 'Data wysyłania SMS', this);
  public readonly renewalId = new DocTextField('renewalId', 'Kontynuacja', this);
  public readonly token = new DocTextField('token', 'Token kontynuacji płatności', this);

  public constructor(stateSource: IStateSource)
  {
    super(stateSource);

    this.insurerName.addValidator(fieldImmutable);
    this.owuSendingRequestDate.addValidator(fieldImmutable);
    this.owuSendingRequested.addValidator(fieldImmutable);
    this.owuSent.addValidator(fieldImmutable);
  }

  protected validate(errors: BError[]): void
  {
    super.validate(errors);
    const requiredConsents = getRequiredConsents(this.source.getDefinedValue(), this.owuSendingRequested.asBool);
    const notGivens = requiredConsents.filter(code =>
    {
      const consent = this.formData.consents.get(code);
      if (!consent.isGiven.asBool)
      {
        consent.isGiven.addError('Zaznaczenie zgody jest wymagane');
      }
      return !consent.isGiven.asBool;
    });
    if (notGivens.length > 0)
    {
      errors.push({ path: null, message: 'Należy udzielić wszystkich wymaganych zgód zaznaczonych na czerwono. Udzielenie tych zgód jest niezbędne do zawarcia umowy.' });
    }

    const insuredSet = new Set<string>();
    this.formData.insuredPersons.forEach((itm) =>
    {
      const duplicate = (itm.firstName.getValue()?.toLocaleLowerCase() || '')
        + ':' + (itm.lastName.getValue()?.toLocaleLowerCase() || '')
        + ':' + (itm.birthDate.getValue()?.toString() || '');
      if (insuredSet.has(duplicate))
      {
        errors.push({ path: itm.firstName.uniqueId, message: 'Nie można ubezpieczyć wielokrotnie tego samego dziecka na jednej polisie' });
      }
      insuredSet.add(duplicate);
    });
  }

  public calculatePremium()
  {
    this.formData.insuredPersons.forEach(p => p.calculatePremium());
  }

  public totalPremium()
  {
    return this.formData.insuredPersons.reduce((p, n) => p + (n.premium.getValue() ?? 0), 0);
  }
}

export class ReportRef extends ListElement
{
  public readonly reportId = new DocTextField('reportId', 'Id raportu', this);
  public readonly collected = new DocBooleanField('collected', 'Czy polisa zebrana w raport', this);
  public readonly reportDate = new DocLocalDateTimeField('reportDate', 'Data utworzenia raportu', this);

  public constructor(stateSource: IFormStateSource)
  {
    super(stateSource);
    this.reportId.addValidator(fieldImmutable);
    this.collected.addValidator(fieldImmutable);
    this.reportDate.addValidator(fieldImmutable);
  }
}

export enum PaymentStatus
{
  unpaid = 1,
  processing = 2,
  paid = 3,
  rejected = 4,
  unknown = 5,
}

export class PaymentData extends ListElement
{
  public readonly paymentStatus = new DocEnumField<PaymentStatus>('paymentStatus', 'Status płatności', this);
  public readonly paymentDate = new DocLocalDateTimeField('paymentDate', 'Data płatności', this);
  public readonly bankAccount = new DocTextField('bankAccount', 'Numer rachunku płatności', this);

  public constructor(stateSource: IFormStateSource)
  {
    super(stateSource);
    this.paymentStatus.addValidator(fieldImmutable);
    this.paymentDate.addValidator(fieldImmutable);
    this.bankAccount.addValidator(fieldImmutable);
  }
}

export class FormData extends ListElement
{
  public readonly affiliateCode = new DocTextField('affiliateCode', 'Kod afiliacyjny', this);
  public readonly beginDate = new DocLocalDateField('beginDate', 'Data początku polisy *', this);
  public readonly endDate = new DocLocalDateField('endDate', 'Data końca polisy', this);
  public readonly policyOwner = this.SubObject(Owner, 'policyOwner');
  public readonly insuredPersons = new FormList('insuredPersons', this, this.InsuredProvider());

  public readonly consents = new FormMap('consents', this, Consent);

  public readonly authToken = new DocTextField('authToken', 'Token', this);

  public constructor(stateSource: IFormStateSource)
  {
    super(stateSource);

    this.beginDate.addValidator(fieldNotEmptyValidator);
    this.beginDate.addValidator(FuncValidator(() =>
    {
      const bd = this.beginDate.getControlValue();
      const minStartDate = LocalDate.today().plus(1, 'days');
      if (bd && (bd.valueOf() < minStartDate.valueOf()))
      {
        return ['Data początku polisy nie może być wcześniejsza niż (' + minStartDate.toDispString() + ')'];
      }
      const maxStartDate = LocalDate.today().plus(6, 'months');
      if (bd && (bd.valueOf() > maxStartDate.valueOf()))
      {
        return ['Data początku polisy nie może być późniejsza niż 6 miesięcy od daty bieżącej.'];
      }
      return [];
    }));
  }

  private InsuredProvider()
  {
    return new FieldContainerProvider(ss =>
    {
      const insured = new Insured(ss);
      this.addInsuredValidators(insured);
      return insured;
    });
  }

  private addInsuredValidators(p: Insured)
  {
    const beginDate = this.beginDate.getValue();
    const is18Plus = () =>
    {
      const birthDate = p.birthDate.getControlValue();
      return !!(beginDate && birthDate && (birthDate.valueOf() < beginDate.plus(-18, 'years').valueOf()));
    };
    p.kidIsStudent.addValidator(ConditionalValidator(is18Plus, fieldNotEmptyValidator));
    p.kidIsStudent.addValidator(ConditionalValidator(is18Plus, new ValueRequired('To ubezpieczenie nie spełni Twoich potrzeb.' +
      'W razie pytań zapraszamy do kontaktu pod numerem telefonu ' + prettyPrintPLPhoneNumber(helpPhone1))));
    p.birthDate.addValidator(FuncValidator(f =>
    {
      const bd = p.birthDate.getControlValue();
      if (beginDate && bd && (bd.valueOf() < beginDate.plus(-25, 'years').valueOf()))
      {
        return ['Ubezpieczyć można tylko osoby do 25 roku życia.'];
      }
      return [];
    }));
  }

  public shouldShowIsStudentQuestion(p: Insured): boolean
  {
    const beginDate = this.beginDate.getValue();
    const birthDate = p.birthDate.getControlValue();
    const is18Plus = !!(beginDate && birthDate && (birthDate.valueOf() < beginDate.plus(-18, 'years').valueOf()));
    if (is18Plus)
    {
      const is25Plus = !!(beginDate && birthDate && (birthDate.valueOf() < beginDate.plus(-25, 'years').valueOf()));
      return !is25Plus;
    }

    return false;
  }

}

export class Person extends ListElement
{
  public readonly firstName = new DocTextField('firstName', 'Imię *', this)
    .withValidator(new MinLength(2));
  public readonly lastName = new DocTextField('lastName', 'Nazwisko *', this)
    .withValidator(new MinLength(2));
  public readonly street = new DocTextField('street', 'Ulica *', this);

  public readonly houseNumber = new DocTextField('houseNumber', 'Numer domu *', this)
    .withValidator(new RegExpValidation(/[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9\.\-\s]*/u,
      () => 'Dozwolone tylko cyfry, litery, znaki: ".", "-" oraz "/"')
    )
    .withValidator(new MaxLength(15));

  public readonly apartmentNumber = new DocTextField('apartmentNumber', 'Numer lokalu', this)
    .withValidator(new RegExpValidation(/[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9\.\-\s]*/u,
      () => 'Dozwolone tylko cyfry, litery, znaki: ".", "-"')
    )
    .withValidator(new MaxLength(10));

  public readonly postCode = new DocTextField('postCode', 'Kod pocztowy *', this)
    .withValidator(PLPostCodeValidator);
  public readonly city = new DocTextField('city', 'Miejscowość *', this)
    .withValidator(new RegExpValidation(/[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s\.\-]*/u,
      () => 'Dozwolone tylko litery, odstępy, znaki: ".", "-"')
    )
    .withValidator(new MaxLength(60));

  public constructor(stateSource: IFormStateSource)
  {
    super(stateSource);
    this.firstName.addValidator(fieldNotEmptyValidator);
    this.lastName.addValidator(fieldNotEmptyValidator);
    this.street.addValidator(fieldNotEmptyValidator);
    this.houseNumber.addValidator(fieldNotEmptyValidator);
    this.postCode.addValidator(fieldNotEmptyValidator);
    this.city.addValidator(fieldNotEmptyValidator);

    this.firstName.addValidator(new MaxLength(50));
    this.firstName.addValidator(
      new RegExpValidation(/[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*/u, () => 'Dozwolone tylko litery')
    );
    this.lastName.addValidator(new MaxLength(50));
    this.lastName.addValidator(
      new RegExpValidation(/[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ \-]*/u, () => 'Dozwolone tylko litery, spacja, -')
    );
  }

  public copyAddressFrom(other: Person)
  {
    this.street.setValue(other.street.getValue());
    this.houseNumber.setValue(other.houseNumber.getValue());
    this.apartmentNumber.setValue(other.apartmentNumber.getValue());
    this.postCode.setValue(other.postCode.getValue());
    this.city.setValue(other.city.getValue());

    this.street.refreshValidation();
    this.houseNumber.refreshValidation();
    this.apartmentNumber.refreshValidation();
    this.postCode.refreshValidation();
    this.city.refreshValidation();
  }

  public addressStr(): string
  {
    return (this.street.getValue() || '') + ' '
      + StringUtils.sepConcat('/', this.houseNumber.getValue() || '', this.apartmentNumber.getValue() || '') + ', '
      + (this.postCode.getValue() || '') + ' '
      + (this.city.getValue() || '');
  }
}

export class Owner extends Person
{
  public readonly pesel = new DocTextField('pesel', 'PESEL *', this);
  public readonly phoneNumber = new DocTextField('phoneNumber', 'Telefon', this);
  public readonly email = new DocTextField('email', 'Adres e-mail *', this);

  public constructor(stateSource: IFormStateSource)
  {
    super(stateSource);
    this.email.addValidator(fieldNotEmptyValidator);
    this.email.addValidator(new EmailValidator());
    this.email.addValidator(new MaxLength(60));
    this.phoneNumber.addValidator(PLPhoneNumberValidator);
    this.pesel.addValidators(fieldNotEmptyValidator, new PESELValidator('Błędny numer PESEL'));
  }
}

export class Insured extends Person
{
  public readonly birthDate = new DocLocalDateField('birthDate', 'Data urodzenia', this);
  public readonly variant = new DocEnumField<RiskVariant>('variant', 'Wariant', this);
  public readonly extremeSports = new DocBooleanField('extremeSports', 'Rozszerzenie na sporty extremalne', this);
  public readonly premium = new DocNumberField('premium', 'Składka', this);
  public readonly insuredSum = new DocNumberField('insuredSum', 'Suma ubezpieczenia', this);
  public readonly policyNumber = new DocTextField('policyNumber', 'Numer polisy', this);
  public readonly bankAccount = new DocTextField('bankAccount', 'Numer konta bankowego', this);
  public readonly kidIsStudent = new DocBooleanField('kidIsStudent', 'Czy Twoje dziecko uczęszcza do szkoły publicznej lub niepublicznej (w tym uczelni wyższej), znajdującej się na terenie RP?', this);

  public constructor(stateSource: IFormStateSource)
  {
    super(stateSource);
    this.birthDate.addValidator(fieldNotEmptyValidator);
    this.birthDate.addValidator(FuncValidator(() =>
    {
      const bd = this.birthDate.getControlValue();
      if (bd && (bd.valueOf() > LocalDate.today().valueOf()))
      {
        return ['Data z przyszłości.'];
      }
      return [];
    }));
    this.variant.addValidators(fieldNotEmptyValidator);
    this.extremeSports.addValidators(fieldNotEmptyValidator);
  }

  public calculatePremium()
  {
    const variant = this.variant.getControlValue();
    if (variant && !this.extremeSports.isControlEmpty())
    {
      const variantDef = Variants[variant];
      this.premium.setValue(this.extremeSports.getControlValue() ? variantDef.premiumExtreme : variantDef.premium);
      this.insuredSum.setValue(variantDef.insuredSum);
    }
    else
    {
      this.premium.setValue(undefined);
      this.insuredSum.setValue(undefined);
    }
  }

  public variantName()
  {
    return this.variant.getValue() === undefined
      ? 'Nie wybrano wariantu ubezpieczenia'
      : (Variants[this.variant.getDefinedValue()].name);
  }
}
