import { IStateSource, StateSource } from '../state/State';
import
{
  FieldContainer, DocElement, FieldContainerProvider, FieldContainerConstructor, IFormStateSource, DocField
} from './FormDoc';
import { BError } from '../model/BError';
import { fileAndForget } from '../utl/ErrUtl';

class MapStateSource
{
  private readonly capturedMap: any;

  public constructor(
    public readonly parent: IStateSource,
    private stateName: string)
  {
    this.capturedMap = this.stateMap;
  }

  public get stateMap(): any | undefined
  {
    return this.parent.getState<any>(this.stateName);
  }

  public getState(key: string): any
  {
    if (this.stateMap)
    {
      return this.stateMap[key];
    }

    return undefined;
  }

  public setNextState(aCallback: (stateMap: object) => void): void
  {
    this.parent.setNextState((sm: any) =>
    {
      let map = sm[this.stateName] || {};
      map = { ...map };
      sm[this.stateName] = map;
      aCallback(map);
    });
  }

  public assignFrom(other: MapStateSource): void
  {
    this.parent.setNextState((sm: any) =>
    {
      const otherMap = other.stateMap;
      sm[this.stateName] = { ...otherMap };
    });
  }

  public remove(key: string): void
  {
    this.parent.setNextState((sm: any) =>
    {
      let map = sm[this.stateName] || {};
      map = { ...map };
      delete map[key];
      sm[this.stateName] = map;
    });
  }

  public onLive<T>(actionOnLive: () => T): T
  {
    return this.parent.onLive<T>(actionOnLive);
  }

  public eqCaptured(o: MapStateSource): boolean
  {
    return this.capturedMap === o.capturedMap;
  }
}

export class MapElementStateSource implements IStateSource
{
  private readonly eqStateMap: any;

  public constructor(
    private mapSource: MapStateSource,
    private key: string)
  {
    this.eqStateMap = this.stateMap;
  }

  private get stateMap(): any
  {
    return this.mapSource.getState(this.key);
  }

  public getState<T>(name: string): T | undefined
  {
    if (this.stateMap)
    {
      return this.stateMap[name];
    }

    return undefined;
  }

  public setNextState(aCallback: (stateMap: any) => void): void
  {
    this.mapSource.setNextState((stateMap: any) =>
    {
      let map = stateMap[this.key];
      map = { ...map };
      stateMap[this.key] = map;
      aCallback(map);
    });
  }

  public onLive<T>(actionOnLive: () => T): T
  {
    return this.mapSource.onLive<T>(actionOnLive);
  }

  public toJSONObject(): any
  {
    if (this.stateMap)
    {
      return { ...this.stateMap };
    }

    return undefined;
  }

  public fromJSONObject(json: any): void
  {
    this.mapSource.setNextState((stateMap: any) => stateMap[this.key] = json);
  }

  public lockState(actionUnderLock: () => void): Promise<void>
  {
    return this.mapSource.parent.lockState(actionUnderLock);
  }

  public eqCaptured(o: IStateSource): boolean
  {
    if (o instanceof MapElementStateSource)
    {
      return this.eqStateMap === o.eqStateMap;
    }

    return false;
  }
}

export class FormMap<T extends FieldContainer> extends DocElement
{
  public readonly dataSource: MapStateSource;
  public readonly savedSource: MapStateSource;
  public readonly errorsSource: MapStateSource;
  public readonly formSource: MapStateSource;
  private readonly formData: IStateSource;
  public readonly: boolean = false;
  private elementProvider: FieldContainerProvider<T>;
  public readonly uniqueId: string;

  public constructor(
    public readonly fieldName: string,
    private parent: FieldContainer,
    elementConstructor: FieldContainerConstructor<T> | FieldContainerProvider<T>)
  {
    super();
    this.uniqueId = parent.uniqueId + '_' + fieldName;
    this.dataSource = new MapStateSource(parent.dataSource, fieldName);
    this.savedSource = new MapStateSource(parent.savedSource, fieldName);
    this.errorsSource = new MapStateSource(parent.errorsSource, fieldName);
    this.formData = new StateSource(parent.formSource, fieldName);
    this.formSource = new MapStateSource(this.formData, fieldName);
    this.parent.add(this);
    if (elementConstructor instanceof FieldContainerProvider)
    {
      this.elementProvider = elementConstructor;
    }
    else
    {
      const provide = (stateSource: IFormStateSource) => new elementConstructor(stateSource);
      this.elementProvider = new FieldContainerProvider(provide);
    }
  }

  public refreshValidation(errors: BError[]): void
  {
    this.createElements().forEach((e) => e.refreshValidation(errors));
  }

  public fillFields(fieldMap: Map<string, DocField<any>>): void
  {
    this.forEach(e => e.fillFields(fieldMap));
  }

  public remove(key: string): void
  {
    this.dataSource.parent.lockState(() =>
    {
      this.dataSource.remove(key);
      this.errorsSource.remove(key);
      this.formSource.remove(key);
    }).catch(fileAndForget);
  }

  public hasErrors(): boolean
  {
    for (const e of this.createElements())
    {
      if (e.hasErrors())
      {
        return true;
      }
    }

    return false;
  }

  public clearErrors(): void
  {
    for (const e of this.createElements())
    {
      e.clearErrors();
    }
  }

  public forEach(callbackfn: (value: T, index: number, array: T[]) => void, thisArg?: any): void
  {
    const elements = this.createElements();
    elements.forEach(callbackfn);
  }

  public map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[]
  {
    const elements = this.createElements();
    return elements.map(callbackfn);
  }

  public filter(callbackfn: (value: T, index: number, array: T[]) => unknown): T[]
  {
    const elements = this.createElements();
    return elements.filter(callbackfn);
  }

  public reduce<U>(
    callbackfn: (previousValue: U, currentValue: T, currentIndex: number, array: T[]) => U, initialValue: U
  ): U
  {
    const elements = this.createElements();
    return elements.reduce(callbackfn, initialValue);
  }

  public get(key: string): T
  {
    return this.createElement(key);
  }

  public safeGet(key: string | undefined): T | undefined
  {
    if (key === undefined)
    {
      return undefined;
    }

    return this.get(key);
  }

  get keys(): string[]
  {
    return this.dataSource.stateMap ? Object.keys(this.dataSource.stateMap) : [];
  }

  get savedKeys(): string[]
  {
    return this.savedSource.stateMap ? Object.keys(this.savedSource.stateMap) : [];
  }

  private createElements(): T[]
  {
    const elements: T[] = [];
    if (this.dataSource.stateMap)
    {
      for (const key of this.keys)
      {
        const element = this.createElement(key);
        elements.push(element);
      }
    }
    return elements;
  }

  private createElement(key: string): T
  {
    const ds = new MapElementStateSource(this.dataSource, key);
    const ss = new MapElementStateSource(this.savedSource, key);
    const es = new MapElementStateSource(this.errorsSource, key);
    const fs = new MapElementStateSource(this.formSource, key);
    const elStateSource = {
      parent: this,
      dataSource: ds, errorsSource: es, formSource: fs, savedSource: ss, uniqueId: this.uniqueId + '_' + key,
      fieldName: key,
    };
    const element = this.elementProvider.provide(elStateSource);
    element.setReadonly(this.readonly);
    return element;
  }

  public eqSaved(): boolean
  {
    for (const e of this.createElements())
    {
      if (!e.eqSaved())
      {
        return false;
      }
    }
    return true;
  }

  public eqCaptured(o: DocElement): boolean
  {
    if (!(o instanceof FormMap))
    {
      return false;
    }

    return this.dataSource.eqCaptured(o.dataSource)
      && this.formData.eqCaptured(o.formData)
      && this.errorsSource.eqCaptured(o.errorsSource);
  }

  public assignFrom(other: FormMap<T>): void
  {
    this.dataSource.assignFrom(other.dataSource);
  }

  public setReadonly(readonly: boolean): void
  {
    this.readonly = readonly;
  }

  public *[Symbol.iterator]()
  {
    const keys = this.keys;
    for (const key of keys)
    {
      yield { key, value: this.get(key) };
    }
  }
}
