import { RegExpValidation } from './TextValidator';

export const rePolandPostCode = /^[0-9]{2}\-[0-9]{3}$/;

export const PLPostCodeValidator =
  new RegExpValidation(
    rePolandPostCode,
    () => 'Nieprawidłowy format kodu pocztowego, oczekiwany ##-###',
    true
  );
