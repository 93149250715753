import moment, { DurationInputArg2 } from 'moment';
import { LocalDate } from './LocalDate';

/**
 * Data + czas oderwana od strefy czasowej, z dokładnością do minut
 * Wewnetrznie reprezentowana jest jako liczba ms od epoki data w strefie UTC
 */
export class LocalDateTime
{
  private readonly value: number;

  /** @param value - liczba ms od epoki w utc */
  public constructor(value: number);
  /**
   * @param year   - rok
   * @param month  - miesiąc (1..12)
   * @param day    - dzien (1..31)
   * @param hour   - godzina (0..23)
   * @param minute - minuta (0..59)
   */
  public constructor(year: number, month: number, day: number, hour: number, minute?: number);
  constructor(yearOrValue: number, month?: number, day?: number, hour?: number, minute?: number)
  {
    this.value = (month !== undefined)
      ? moment.utc({ year: yearOrValue, month: month - 1, day, hour, minute }).valueOf()
      : yearOrValue;
  }

  /*
   * Wartosc wyrażona w ms od 01.01.1970 UTC
   */
  public valueOf(): number
  {
    return this.value;
  }

  public toString(): string
  {
    return this.toMomentUTC().format('YYYY-MM-DD HH:mm');
  }

  public toDispString(): string
  {
    return this.toMomentUTC().format('DD.MM.YYYY HH:mm');
  }

  public static now(): LocalDateTime
  {
    // Moment z lokalną datą-czasem
    const m = moment();
    // Przesunięty tak, aby wskazywać na tę samą datę-czas zegarową ale w strefie UTC
    const c = m
      .seconds(0)
      .milliseconds(0)
      .add(m.utcOffset(), 'minutes');
    return new LocalDateTime(c.valueOf());
  }

  public static today(): LocalDateTime
  {
    // Moment z lokalną datą-czasem
    const m = moment();
    // Przesunięty tak, aby wskazywać na tę samą datę-czas zegarową ale w strefie UTC
    const c = m
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .add(m.utcOffset(), 'minutes');
    return new LocalDateTime(c.valueOf());
  }

  public eq(other: LocalDateTime | undefined): boolean
  {
    return (other !== undefined) && (this.value === other.value);
  }

  public compareTo(other: LocalDateTime | LocalDate | undefined): number
  {
    if (other === undefined)
    {
      return 1;
    }

    return this.value - other.valueOf();
  }

  public toJSON(): any
  {
    return this.value;
  }

  public static fromJSON(json: any): LocalDateTime | undefined
  {
    return (typeof json === 'number') ? new LocalDateTime(json) : undefined;
  }

  /**
   * Oddaje jako moment.
   * Ze względu na sposób reprezentacji, będzie to ta data/czas w strefie UTC
   */
  public toMomentUTC(): moment.Moment
  {
    return moment.utc(this.value);
  }

  public static fromMoment(m: moment.Moment): LocalDateTime
  {
    const [year, month, date, hour, minute, second] =
      [m.year(), m.month(), m.date(), m.hour(), m.minute(), 0];
    const v = moment.utc({ year, month, date, hour, minute, second });
    return new LocalDateTime(v.valueOf());
  }

  public toLocalDate(): LocalDate
  {
    return LocalDate.fromMoment(this.toMomentUTC());
  }

  /**
   * Oddaje date przesunieta o wskazana liczbe jednostek
   */
  public plus(amount: number, unit: DurationInputArg2): LocalDateTime
  {
    const m = this.toMomentUTC().add(amount, unit);
    return LocalDateTime.fromMoment(m);
  }

  public toUserInput(asDate?: boolean): string
  {
    return asDate ? this.toLocalDate().toUserInput() : this.toMomentUTC().format('YYYY-MM-DDTHH:mm');
  }

  public static fromUserInput(s: string, asDate?: boolean): LocalDateTime
  {
    const date = new Date(Date.parse(s));
    return new LocalDateTime(date.getFullYear(), date.getMonth() + 1, date.getDate(),
      asDate ? 0 : date.getHours(),
      asDate ? 0 : date.getMinutes());
  }

}
