import { DocField, FieldValidator } from '../FormDoc';

export type FieldValidatorFunc<T> = (field: DocField<T>) => string[];

/**
 * Oddaje walidator uruchamiający przekazaną funkcję
 */
export function FuncValidator<T>(func: FieldValidatorFunc<T>): FieldValidator<T>
{
  return new class
  {
    public validate(field: DocField<T>): string[]
    {
      return func(field);
    }
  }();
}
